import React from "react";
import { AiFillDashboard, AiOutlineGift } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { GoInbox } from "react-icons/go";
import { RiBillLine } from "react-icons/ri";
import { RouteComponentProps, StaticContext } from "react-router";
import BrandManager from "../container/BrandManager";
import ChangePassword from "../container/ChangePassword";
import CouponManager from "../container/CouponManager";
import CustomerManager from "../container/CustomerManager";
import GiftCardContainer from "../container/GiftCardContainer";
import GiftCardDetail from "../container/GiftCardDetail";
import GiftCardManager from "../container/GiftCardManager";
import HomePartner from "../container/HomePartner";
import IncomeCustomer from "../container/IncomeCustomer";
import IncomeScreenForCustomer from "../container/IncomeScreenForCustomer";
import Login from "../container/Login";
import OrderManager from "../container/OrderManager";
import Statistics from "../container/Statistics";

export enum TypeScreen {
	public = "public",
	admin = "admin",
	customer = "customer",
	adminAndCustomer = "admin_customer",
}

export const routers: RouteComponent[] = [
	{
		component: Login,
		label: "Login",
		link: "/login",
		typeAuthen: TypeScreen.public,
		icon: <AiFillDashboard />,
	},
	{
		component: ChangePassword,
		label: "changePassword",
		link: "/change-password",
		typeAuthen: TypeScreen.adminAndCustomer,
		icon: <AiFillDashboard />,
	},
	{
		component: Statistics,
		label: "Dashboard",
		link: "/admin/dashboard",
		typeAuthen: TypeScreen.admin,
		icon: <AiFillDashboard />,
	},
	{
		component: CustomerManager,
		label: "Khách hàng",
		link: "/admin/customer",
		typeAuthen: TypeScreen.admin,
		icon: <FiUsers />,
	},
	{
		component: GiftCardManager,
		label: "Gift Card",
		link: "/admin/gift-card",
		typeAuthen: TypeScreen.admin,
		icon: <AiOutlineGift />,
	},
	// {
	// 	component: CouponManager,
	// 	label: "Mã giảm giá",
	// 	link: "/admin/coupon",
	// 	typeAuthen: TypeScreen.admin,
	// 	icon: <RiBillLine />,
	// },
	{
		component: OrderManager,
		label: "Đơn hàng",
		link: "/admin/order",
		typeAuthen: TypeScreen.admin,
		icon: <RiBillLine />,
	},
	{
		component: IncomeCustomer,
		label: "Đơn hàng",
		link: `/admin/coin-customer/:id`,
		typeAuthen: TypeScreen.admin,
		icon: <RiBillLine />,
	},
	// IncomeCustomer
	{
		component: BrandManager,
		label: "Hãng",
		link: "/admin/brand",
		typeAuthen: TypeScreen.admin,
		icon: <GoInbox />,
	},
	{
		component: HomePartner,
		label: "Gift card",
		link: "/home-partner",
		typeAuthen: TypeScreen.customer,
		icon: <GoInbox />,
	},
	{
		component: IncomeScreenForCustomer,
		label: "Wallet",
		link: "/my-coin",
		typeAuthen: TypeScreen.customer,
		icon: <GoInbox />,
	},
	{
		component: GiftCardContainer,
		label: "Shop",
		link: "/gift-card",
		typeAuthen: TypeScreen.customer,
		icon: <GoInbox />,
	},
	{
		component: GiftCardDetail,
		label: "Chi tiết",
		link: "/product",
		typeAuthen: TypeScreen.customer,
		icon: <GoInbox />,
	},
];
export const routersMap = new Map(routers.map((item) => [item.link, item]));

export interface RouteComponent {
	link: string;
	component:
		| React.ComponentType<any>
		| React.ComponentType<RouteComponentProps<any, StaticContext, any>>;
	typeAuthen: TypeScreen;
	icon: React.ReactElement;
	label: string;
}
