import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import IntegrationNotistack from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/MuiTheme";
import { store } from "./rematch/store";
import SystemMaintenance from "./component/genaral-component/SystemMaintenance";
import {
	StylesProvider,
	createGenerateClassName,
} from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
	productionPrefix: "c",
});

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<StylesProvider generateClassName={generateClassName}>
				{/* <SystemMaintenance /> */}
			</StylesProvider>
			<IntegrationNotistack />
		</ThemeProvider>
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
