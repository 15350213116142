/* eslint-disable jsx-a11y/alt-text */
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { BiSort } from "react-icons/bi";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";
import { FilterHelper } from "../../helpers/FilterHelper";
import { formatterMoney } from "../../helpers/StringHelper";
import { ListFilter } from "luong-base-model";
import { Brand } from "../../model/base-gift-card/model/Brand";
import { GiftCardUser } from "../../model/base-gift-card/model/GiftCardUser";
import { EStatusOrder } from "../../model/base-gift-card/model/Order";
import { useGlobalStyles } from "../../style/GlobalStyle";
import theme from "../../theme/MuiTheme";
const useStyle = makeStyles((theme) => ({
	root: {
		borderRight: `1px solid ${theme.palette.grey[200]}`,
		width: 300,
	},
	frLogo: {
		borderRadius: theme.spacing(1),
		overflow: "hidden",
		height: "2rem",
		width: "2rem",
	},
	logoGiftImg: {
		height: "100%",
	},
	filterItem: {
		cursor: "pointer",
	},
}));

type Props = {
	query: ListFilter<any>;
	onQuery: (query: ListFilter<any>) => void;
	bands: Brand[];
};
export default function FilterGiftCardHome(props: Props) {
	const classes = useStyle();
	const globalStyle = useGlobalStyles();
	const filterBrand = (brand: Brand) => {
		if (brand.id === "all") {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					["brandId" as any]: undefined,
				},
			});
		} else {
			console.log(brand);

			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					["brandId" as any]: brand.id,
				} as any,
			});
		}
	};
	const filterStatusOrder = (status: EStatusOrder | "all") => {
		if (status === "all") {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					["orderStatus" as any]: undefined,
				},
			});
		} else {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					["orderStatus" as any]: status,
				},
			});
		}
	};
	const filterStatusUsed = (status: boolean | "all") => {
		if (status === "all") {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					["isUsedGiftCardUser" as any]: undefined,
				},
			});
		} else {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					["isUsedGiftCardUser" as any]: status,
				},
			});
		}
	};
	const checkFilterBrand = (id: string): boolean => {
		if (
			props.query &&
			props.query.filter &&
			props.query.filter.brandId === id
		) {
			return true;
		} else if (id === "all" && !props.query?.filter?.brandId) return true;
		return false;
	};
	const getIconSort = (
		sort: string | string[],
		valueCheck: string
	): React.ReactElement => {
		const newSort = typeof sort === "string" ? sort : sort[0];
		if (!newSort || _.snakeCase(valueCheck) !== _.snakeCase(newSort)) {
			return <BiSort />;
		}
		if (newSort.startsWith("-")) {
			return <FaSortAmountDownAlt color={theme.palette.secondary.main} />;
		} else {
			return <FaSortAmountUpAlt color={theme.palette.secondary.main} />;
		}
	};
	const sortFiler = (key: string) => {
		props.onQuery(FilterHelper.addSort(props.query, key));
	};

	const getValueSortByKey = (key: string) => {
		if (!props.query || !props.query.filter) return "all";
		let value: any = props.query?.filter[key];
		// if (value) value = value[0];
		if (typeof value === "boolean") return value;
		return value || "all";
	};

	return (
		<Grid className={clsx(globalStyle.pp1, globalStyle.pt0, classes.root)}>
			<Grid>
				<Grid className={clsx(globalStyle.pp1)}>
					<Grid className={clsx(globalStyle.pp1)}>
						<Typography variant="h6">LỌC KHÁC</Typography>
					</Grid>
					<Grid className={clsx(globalStyle.pp1)}>
						<FormControl variant="outlined">
							<InputLabel></InputLabel>
							<Select
								value={getValueSortByKey("isUsedGiftCardUser")}
								// isUsedGiftCardUser
								onChange={(e) =>
									filterStatusUsed(e.target.value as any)
								}
							>
								<MenuItem value={"all"}>Tất cả</MenuItem>
								<MenuItem value={true as any}>
									Đã sử dụng
								</MenuItem>
								<MenuItem value={false as any}>
									Chưa sử dụng
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid className={clsx(globalStyle.pp1)}>
						<FormControl variant="outlined">
							<InputLabel></InputLabel>
							<Select
								value={getValueSortByKey("orderStatus")}
								onChange={(e) =>
									filterStatusOrder(e.target.value as any)
								}
							>
								<MenuItem value={"all"}>Tất cả</MenuItem>
								<MenuItem value={EStatusOrder.IN_DEBT}>
									Chưa thanh toán
								</MenuItem>
								<MenuItem value={EStatusOrder.COMPLETE}>
									Đã thanh toán
								</MenuItem>
								<MenuItem value={EStatusOrder.WAITINGCONFIRM}>
									Chờ thanh toán
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
			<Grid>
				<Grid className={clsx(globalStyle.pp1)}>
					<Grid className={clsx(globalStyle.pp1)}>
						<Typography variant="h6">LỌC THEO HÃNG</Typography>
					</Grid>
				</Grid>
				<Grid
					className={clsx(globalStyle.pp1)}
					container
					alignContent="center"
					alignItems="center"
				>
					{[{ id: "all", name: "Tất cả" }, ...props.bands].map(
						(item) => (
							<Grid
								container
								className={clsx(
									globalStyle.pl1,
									classes.filterItem
								)}
								alignContent="center"
								alignItems="center"
								onClick={() => filterBrand(item)}
							>
								<Grid className={classes.frLogo}>
									<img
										src="/image/gift.png"
										className={classes.logoGiftImg}
									></img>
								</Grid>
								<Grid className={clsx(globalStyle.pp1)}>
									<Typography
										style={{
											color: checkFilterBrand(
												item.id || "all"
											)
												? theme.palette.secondary.main
												: "",
										}}
									>
										{item.name || ""}
									</Typography>
								</Grid>
							</Grid>
						)
					)}
				</Grid>
			</Grid>
			<Grid className={clsx(globalStyle.pp1)}>
				<Grid className={clsx(globalStyle.pp1)}>
					<Typography variant="h6">LỌC THEO HÃNG</Typography>
				</Grid>
				<Grid container alignContent="center" alignItems="center">
					<Grid
						container
						className={clsx(globalStyle.pl1)}
						alignContent="center"
						alignItems="center"
					>
						<Grid>
							{getIconSort(
								props.query.sort || "",
								"createdAtGiftCardUser"
							)}
						</Grid>
						<Grid className={clsx(globalStyle.pp1)}>
							<Typography
								className={clsx(classes.filterItem)}
								onClick={() =>
									sortFiler("createdAtGiftCardUser")
								}
							>
								Sắp xếp theo ngày mua
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						className={clsx(globalStyle.pl1)}
						alignContent="center"
						alignItems="center"
					>
						<Grid>
							{getIconSort(
								props.query.sort || "",
								"denomination"
							)}
						</Grid>
						{/* createdAtGiftCardUser */}
						<Grid className={clsx(globalStyle.pp1)}>
							<Typography
								className={clsx(classes.filterItem)}
								onClick={() => sortFiler("denomination")}
							>
								Sắp xếp theo giá
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
