/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { formatterMoney } from "../../helpers/StringHelper";
import { GiftCard } from "../../model/base-gift-card/model/GiftCard";
import { useGlobalStyles } from "../../style/GlobalStyle";
const useStyle = makeStyles((theme) => ({
	root: {
		width: 280,
		height: 180,
		background: "#fff",
		position: "relative",
		border: `1px solid ${theme.palette.grey[200]}`,
		padding: theme.spacing(2),
		borderRadius: theme.spacing(1),
	},
	frPercentDiscount: {
		height: 60,
		width: 60,
		borderRadius: "50%",
		background: theme.palette.secondary.main,
		color: "white",
		position: "absolute",
		top: theme.spacing(1),
		left: theme.spacing(1),
	},
	buttonBuy : {
		boxShadow : "none"
	}
}));
type Props = {
	item: GiftCard;
	onOrder: (item: GiftCard) => void;
};
export default function GiftCardItem(props: Props) {
	const [isShowDetail, setShowDetail] = useState(false);
	const classes = useStyle();
	const globalsStyle = useGlobalStyles();
	useEffect(() => {
		if (isShowDetail) setShowDetail(false);
	}, [props.item]);
	return (
		<Grid>
			<Grid
				className={clsx(classes.root)}
				container
				direction="column"
				alignItems="center"
				justify="space-around"
			>
				<Grid
					className={clsx(classes.frPercentDiscount)}
					container
					justify="center"
					alignItems="center"
				>
					<Typography>-{props.item.percentDiscount}%</Typography>
				</Grid>
				<Grid>
					<Typography variant="h5">
						{props.item.brand?.name || ""}
					</Typography>
				</Grid>
				<Grid container justify="center">
					<Grid className={clsx(globalsStyle.pr2)}>
						<Typography>Giá trị</Typography>
					</Grid>
					<Grid>
						<Typography color="secondary">
							{formatterMoney.format(
								props.item.denomination || 0
							)}
						</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Button
						fullWidth
						className = {classes.buttonBuy}
						variant="contained"
						onClick={() => props.onOrder(props.item)}
					>
						Mua với{" "}
						{formatterMoney.format(props.item.priceSale || 0)}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
