import {
	Button,
	Fab,
	Grid,
	Hidden,
	IconButton,
	makeStyles,
	Typography,
	Zoom,
} from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Pagination } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { IoIosOptions } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../component/Footer";
import ListGrid from "../component/genaral-component/ListGrid";
import PopupDescGiftCardUser from "../component/gift-card/PopupDescGiftCardUser";
import PopupOptionPayment from "../component/income/PopupOptionPayment";
import Menu from "../component/Menu";
import DashboardPartner from "../component/partner/DashboardPartner";
import FilterGiftCardHome from "../component/partner/FilterGiftCardHome";
import GiftCardItemPartner from "../component/partner/GiftCardItemPartner";
import {
	brandController,
	giftCardUserController,
	orderController,
} from "../controller";
import { useCrudHook } from "../helpers/CrudHook";
import { formatterMoney } from "../helpers/StringHelper";
import {
	StatisticsBrand,
	StatisticsIsUsed,
} from "../model/base-gift-card/controller/IGiftCardUserController";
import {
	ETypePayment,
	StatisticStatusOrder,
} from "../model/base-gift-card/controller/IOrderController";
import { Brand } from "../model/base-gift-card/model/Brand";
import { GiftCard } from "../model/base-gift-card/model/GiftCard";
import { GiftCardUser } from "../model/base-gift-card/model/GiftCardUser";
import { EStatusOrder } from "../model/base-gift-card/model/Order";
import { Dispatch, RootState } from "../rematch/store";
import { useGlobalStyles } from "../style/GlobalStyle";
import theme from "../theme/MuiTheme";
const useStyle = makeStyles((theme) => ({
	fr: {
		padding: theme.spacing(3),
		border: `1px solid ${theme.palette.grey[200]}`,
	},
}));
export default function HomePartner() {
	const classes = useStyle();
	const globalStyle = useGlobalStyles();
	const dispath = useDispatch<Dispatch>();
	const authen = useSelector((state: RootState) => state.authen);
	const [statistics, setStatistics] = useState<{
		statisticsIsUsed: StatisticsIsUsed;
		statisticsBrand: StatisticsBrand[];
		statisticStatusOrder: StatisticStatusOrder;
		statisticStatusPriceOrder: StatisticStatusOrder;
	}>({
		statisticsBrand: [],
		statisticsIsUsed: {},
		statisticStatusOrder: {},
		statisticStatusPriceOrder: {},
	});

	const crudGiftCardUser = useCrudHook<GiftCardUser>({
		controller: giftCardUserController,
		listController: giftCardUserController.listForUser,
		onAfterSave: (e) => {
			getStatistics();
		},
		initQuery: {
			pageSize: 10,
		},
	});
	const [brand, setBrand] = useState<Brand[]>();
	const [state, setState] = useState<{
		listGiftCardSelected: GiftCardUser[];
		listGiftCardSelectedMap: Map<string, GiftCardUser>;
		isOpenNavBar: boolean;
		isShowPopupOptionPayment: boolean;
	}>({
		listGiftCardSelected: [],
		listGiftCardSelectedMap: new Map<string, GiftCardUser>(),
		isOpenNavBar: false,
		isShowPopupOptionPayment: false,
	});

	const onClickSelect = (item: GiftCardUser) => {
		if (item.order?.status !== EStatusOrder.IN_DEBT) return;
		const get = state?.listGiftCardSelectedMap.get(item.id || "");
		const list = state.listGiftCardSelectedMap;
		if (get) {
			list.delete(get.id || "");
		} else {
			list.set(item.id || "", item);
		}
		setState({
			...state,
			listGiftCardSelected: Array.from(list.values()),
			listGiftCardSelectedMap: list,
			isOpenNavBar: false,
		});
	};

	const onConfirmPayment = (type: ETypePayment) => {
		const orderIds = state.listGiftCardSelected.map(
			(item) => item.order?.id || ""
		);
		orderController
			.confirmPayment({ orderId: orderIds, typePayment: type })
			.then((res) => {
				crudGiftCardUser.onRefreshList();
				if (type == ETypePayment.WITH_COIN) {
					dispath.notification.success(
						"Đơn hàng của bạn đã được thanh toán thành công"
					);
				} else {
					dispath.notification.success("Đang chờ được xác nhận");
				}
				getStatistics();
				setState({
					...state,
					listGiftCardSelected: [],
					listGiftCardSelectedMap: new Map(),
					isOpenNavBar: false,
					isShowPopupOptionPayment: false,
				});
			});
	};

	const getStatistics = () => {
		Promise.all([
			brandController.find({}),
			giftCardUserController.statisticsBrand(),
			giftCardUserController.statisticsIsUsed(),
			giftCardUserController.statisticsOrder(),
			giftCardUserController.statisticsOrderPrice(),
		]).then((_res) => {
			setBrand(_res[0]);
			setStatistics({
				statisticsBrand: _res[1],
				statisticsIsUsed: _res[2],
				statisticStatusOrder: _res[3],
				statisticStatusPriceOrder: _res[4],
			});
		});
	};

	const getTotalPrice = (): number => {
		let total = 0;
		state?.listGiftCardSelectedMap.forEach((item) => {
			total += item.order?.totalPrice || 0;
		});
		return total;
	};
	useEffect(() => {
		getStatistics();
	}, []);

	const onChangeUpdate = (item: GiftCardUser) => {
		crudGiftCardUser.onSave(item);
	};
	const toggleDrawer = (open: boolean) => {
		setState({
			...state,
			isOpenNavBar: open,
		});
	};
	const handleCancelPopupPaymet = () => {
		setState({
			...state,
			isShowPopupOptionPayment: false,
		});
	};

	const handleShowPopupPayment = () => {
		setState({
			...state,
			isShowPopupOptionPayment: true,
		});
	};

	return (
		<Grid container>
			<PopupOptionPayment
				isDisplay={state.isShowPopupOptionPayment}
				onCancel={handleCancelPopupPaymet}
				typeCustomer={authen.info?.typeCustomer as any}
				totalMoney={getTotalPrice()}
				onPayment={(type) => onConfirmPayment(type)}
			/>
			{Boolean(state.listGiftCardSelected.length) && (
				<Fab
					variant="extended"
					color="secondary"
					style={{
						position: "fixed",
						bottom: theme.spacing(8),
						right: theme.spacing(8),
						zIndex: 1000,
					}}
					onClick={() => handleShowPopupPayment()}
				>
					Thanh toán ({formatterMoney.format(getTotalPrice())})
				</Fab>
			)}
			<PopupDescGiftCardUser
				isDisplay={crudGiftCardUser.isShowPopup}
				item={crudGiftCardUser.itemSelected}
				onCancel={crudGiftCardUser.onCancelPopup}
				onEdit={crudGiftCardUser.onSave}
			/>
			<SwipeableDrawer
				anchor={"right"}
				open={state.isOpenNavBar}
				onClose={() => {
					toggleDrawer(false);
				}}
				onOpen={() => {
					toggleDrawer(true);
				}}
			>
				<Grid
					container
					className={clsx(globalStyle.pp2)}
					justify="flex-start"
					style={{
						maxWidth: 300,
					}}
				>
					<FilterGiftCardHome
						bands={brand || []}
						onQuery={(query) => {
							setState({
								...state,
								isOpenNavBar: false,
							});
							crudGiftCardUser.setQuery(query);
						}}
						query={crudGiftCardUser.query}
					/>
				</Grid>
			</SwipeableDrawer>

			<Grid container justify="center">
				<Grid
					lg={10}
					style={{
						marginTop: 90,
					}}
				>
					<Grid className={clsx(classes.fr)} container>
						<DashboardPartner
							statisticStatusOrder={
								statistics.statisticStatusOrder
							}
							statisticStatusPriceOrder={
								statistics.statisticStatusPriceOrder
							}
							statisticsBrand={statistics.statisticsBrand}
							statisticsIsUsed={statistics.statisticsIsUsed}
						/>
					</Grid>
					<Grid
						className={clsx(classes.fr, globalStyle.mt3)}
						container
					>
						<Grid
							container
							alignItems="center"
							alignContent="center"
							className={clsx(globalStyle.pp4)}
						>
							<Hidden mdUp>
								<Grid
									style={{
										position: "fixed",
										top: theme.spacing(1),
										right: theme.spacing(1),
										zIndex: 10000,
									}}
									className={clsx(globalStyle.pr2)}
								>
									<IconButton
										onClick={() =>
											toggleDrawer(!state.isOpenNavBar)
										}
									>
										<IoIosOptions />
									</IconButton>
								</Grid>
							</Hidden>
							<Grid>
								<Typography variant="h5">
									Sản phẩm đã mua
								</Typography>
							</Grid>
						</Grid>
						<Grid container className={clsx(globalStyle.pp1)}>
							<Grid md={3}>
								<Hidden smDown>
									<FilterGiftCardHome
										bands={brand || []}
										onQuery={crudGiftCardUser.setQuery}
										query={crudGiftCardUser.query}
									/>
								</Hidden>
							</Grid>
							<Grid md={9} container>
								<ListGrid
									heightItem={"380px"}
									minWidthItem={"330px"}
									gridGap={20}
								>
									{crudGiftCardUser.pagingList.rows?.map(
										(item, index) => (
											<Zoom
												in={true}
												timeout={index * 50}
											>
												<Grid
													container
													justify="center"
												>
													<GiftCardItemPartner
														item={item}
														onEditDesc={
															crudGiftCardUser.onShowPopup
														}
														isSelect={Boolean(
															state?.listGiftCardSelectedMap?.get(
																item.id || ""
															)
														)}
														onChangeUpdate={
															onChangeUpdate
														}
														onClickSelect={
															onClickSelect
														}
													/>
												</Grid>
											</Zoom>
										)
									)}
								</ListGrid>
								<Grid
									container
									justify="center"
									className={clsx(globalStyle.pp4)}
								>
									<Pagination
										count={
											crudGiftCardUser.pagingList
												.totalPages
										}
										onChange={(e, page) => {
											crudGiftCardUser.setQuery({
												...crudGiftCardUser.query,
												page: page,
											});
										}}
										page={crudGiftCardUser.pagingList.page}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid id="about" container>
				<Footer></Footer>
			</Grid>
		</Grid>
	);
}
