/* eslint-disable @typescript-eslint/no-empty-interface */
import moment from 'moment';
import { ListFilter, Paging } from 'luong-base-model';
import { GiftCard } from '../model/GiftCard';
import { IBaseController } from './IBaseController';
export interface IGiftCardController extends IBaseController<GiftCard> {
  listForCustomer(params: ListFilter<GiftCard>): Promise<Paging<GiftCard>>;
}

export class ListFilterGiftCardAdmin extends ListFilter<GiftCard> {
  filterUsed?: FilterUsed;
  from?: Date = new Date(0);
  to?: Date = moment().add('days', 1).toDate();
}

export enum FilterUsed {
  used = 'used',
  notUsed = 'notUsed',
  all = 'all',
}
