/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Income } from "../../model/base-gift-card/model/Income";
import { useGlobalStyles } from "../../style/GlobalStyle";
import BaseDialog from "../genaral-component/BaseDialog";

type Props = {
	isDisplay: boolean;
	item: Income;
	onEdit: (item: Income) => void;
	onCancel: () => void;
};
const validate = Yup.object({
	amount: Yup.number().required("Không được để trống !!"),
});

export default function PopupAddIncomeForUser(props: Props) {
	const formik = useFormik<Income>({
		initialValues: {} as Income,
		validationSchema: validate,
		onSubmit: () => {
			props.onEdit(formik.values);
		},
	});
	const onSubmit = () => {
		formik.handleSubmit();
		formik.setTouched(_.mapValues(new Income(), () => true));
	};

	useEffect(() => {
		if (props.isDisplay) {
			formik.setValues(
				_.cloneDeep({
					...props.item,
				})
			);
			formik.setTouched(_.mapValues(new Income(), () => false));
		}
	}, [props]);
	const globalStyles = useGlobalStyles();
	return (
		<Grid>
			<BaseDialog
				isDisplay={props.isDisplay}
				onCancel={props.onCancel}
				onClickConfirm={() => {
					onSubmit();
				}}
				title="Thêm số dư cho khách hàng"
			>
				<Grid container direction="column" justify="space-around">
					<Grid>
						<TextField
							value={formik.values.amount}
							helperText={
								formik.touched.amount && formik.errors.amount
							}
							type="number"
							name="amount"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Số tiền"
						></TextField>
					</Grid>
				</Grid>
			</BaseDialog>
		</Grid>
	);
}
