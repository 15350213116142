/* eslint-disable @typescript-eslint/no-empty-interface */
import { Order } from '../model/Order';
import { IBaseController } from './IBaseController';
export interface IOrderController extends IBaseController<Order> {
  createOrder(param: PropsCreateOrder): Promise<Order>;
  statisticStatusOrderPrice(
    params: FilterStatistic,
  ): Promise<StatisticStatusOrder>;
  statisticStatusOrderAmount(
    params: FilterStatistic,
  ): Promise<StatisticStatusOrder>;
  intervalRevenue(params: FilterStatistic): Promise<IntervalOrder[]>;
  intervalAmount(params: FilterStatistic): Promise<IntervalOrder[]>;
  confirmPayment(params: {
    orderId: string[];
    typePayment: ETypePayment;
  }): Promise<Order[]>;
  confirmComplete(params: {
    orderId: string[];
    typePayment: ETypePayment;
  }): Promise<Order[]>;
}

export interface FilterStatistic {
  from?: Date;
  to?: Date;
  interval: 'day';
}

export enum EIntervalStatistic {
  DAY = 'day',
  MONTH = 'month',
}

export interface IntervalOrder {
  date: Date;
  total: number;
  totalComplete: number;
  totalInDebt: number;
  totalWaitingConfirm: number;
}
export interface PropsCreateOrder extends Order {
  productId: string;
  typePayment?: ETypePayment;
}

export enum ETypePayment {
  IN_DEBT = 'IN_DEBT',
  WITH_COIN = 'WITH_COIN',
}
export interface StatisticRevenue {
  sumAll: number;
  sumDay: number;
  sumWeek: number;
  sumMonth: number;
  sumYear: number;
}
export interface StatisticAmount extends StatisticRevenue {}
export interface StatisticStatusOrder {
  total?: number;
  totalComplete?: number;
  totalInDebt?: number;
  totalWaitingConfirm?: number;
}

export interface StatisticsPriceByCustomer {
  customerId?: string;
  totalPrice?: number;
  totalCompletePrice?: number;
  totalInDebtPrice?: number;
  totalWaitingConfirm?: number;
}

export interface StatisticsAmountByCustomer {
  customerId?: string;
  totalAmount?: number;
  totalCompleteAmount?: number;
  totalInDebtAmount?: number;
  totalWaitingConfirmAmount?: number;
}
