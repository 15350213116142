import {
	Button,
	Grid,
	IconButton,
	makeStyles,
	Slide,
	TextField,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { userController } from "../controller";
import { Dispatch, RootState } from "../rematch/store";
import { useGlobalStyles } from "../style/GlobalStyle";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100vh",
		background: "url('https://devforum.info/DEMO/LoginForm1/bg.jpg')",
		backgroundSize: "cover",
	},
	frLogin: {
		width: 420,
		// height : 320,
		background: "white",
		padding: theme.spacing(6),
	},
	titleLogin: {
		fontWeight: 500,
		// borderLeft : `${1}px solid ${theme.palette.primary.main}`
	},
	linkBackHome: {
		textDecoration: "none",
		color: theme.palette.primary.main,
		padding: theme.spacing(2),
	},
}));
const validate = Yup.object({
	oldPassword: Yup.string().required("Không được để trống"),
	password: Yup.string().required("Không được để trống"),
	passwordAgain: Yup.string().required("Không được để trống"),
});
export default function ChangePassword() {
	const dispatch = useDispatch<Dispatch>();
	const authen = useSelector((state: RootState) => state.authen);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const formik = useFormik<{
		oldPassword: string;
		password: string;
		passwordAgain: string;
	}>({
		validationSchema: validate,
		initialValues: { password: "", oldPassword: "", passwordAgain: "" },
		onSubmit: (values) => {
			userController
				.changePassword({
					oldPassword: values.oldPassword,
					newPassword: values.password,
				})
				.then((res) => {
					dispatch.notification.success(
						"Thay đổi mật khẩu thành công"
					);
				})
				.catch((err) => setError("Mật khẩu không chính xác"));
		},
	});
	const onSubmit = () => {
		if (formik.values.password !== formik.values.passwordAgain) {
			formik.setErrors({
				...formik.errors,
				passwordAgain: "Mật khẩu không trùng khớp",
			});
		}
		formik.handleSubmit();
		formik.setTouched({
			password: true,
			oldPassword: true,
			passwordAgain: true,
		});
	};
	const classes = useStyles();
	const globalStyles = useGlobalStyles();
	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.root}
		>
			<Slide in={true} timeout={900} direction="down">
				<Grid className={classes.frLogin} container>
					<Grid className={globalStyles.pp1}>
						<Typography
							className={classes.titleLogin}
							variant="h4"
							color="primary"
						>
							Đổi mật khẩu
						</Typography>
						<Typography color="error" variant="caption">
							{error}
						</Typography>
					</Grid>
					<TextField
						className={globalStyles.mm1}
						fullWidth
						label="Mật khẩu cũ"
						name="oldPassword"
						value={formik.values.oldPassword}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						type={showPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={() =>
										setShowPassword(!showPassword)
									}
								>
									{showPassword ? <BsEye /> : <BsEyeSlash />}
								</IconButton>
							),
						}}
						error={
							!!(
								formik.touched.oldPassword &&
								formik.errors.oldPassword
							)
						}
						helperText={
							formik.touched.oldPassword &&
							formik.errors.oldPassword
						}
					></TextField>
					<TextField
						fullWidth
						label="Mẩu khẩu mới"
						className={globalStyles.mm1}
						name="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						type={showPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={() =>
										setShowPassword(!showPassword)
									}
								>
									{showPassword ? <BsEye /> : <BsEyeSlash />}
								</IconButton>
							),
						}}
						error={
							!!(
								formik.touched.password &&
								formik.errors.password
							)
						}
						helperText={
							formik.touched.password && formik.errors.password
						}
					/>
					<TextField
						fullWidth
						label="Xác nhận mật khẩu"
						className={globalStyles.mm1}
						name="passwordAgain"
						value={formik.values.passwordAgain}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						type={showPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<IconButton
									onClick={() =>
										setShowPassword(!showPassword)
									}
								>
									{showPassword ? <BsEye /> : <BsEyeSlash />}
								</IconButton>
							),
						}}
						error={
							!!(
								formik.touched.passwordAgain &&
								formik.errors.passwordAgain
							)
						}
						helperText={
							formik.touched.passwordAgain &&
							formik.errors.passwordAgain
						}
					/>
					<Button
						className={clsx(globalStyles.mm1, globalStyles.mt3)}
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => onSubmit()}
					>
						Xác nhận đổi
					</Button>

					<Link
						className={classes.linkBackHome}
						to={
							authen.role == "admin"
								? "/admin/dashboard"
								: "/gift-card"
						}
					>
						Trở lại trang chủ
					</Link>
				</Grid>
			</Slide>
		</Grid>
	);
}
