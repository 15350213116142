import { ListFilter, Paging } from "luong-base-model/lib";
import { IIncomeController } from "../model/base-gift-card/controller/IIncomeController";
import { Finance } from "../model/base-gift-card/model/Finance";
import { Income } from "../model/base-gift-card/model/Income";
import { BaseController } from "./BaseController";

export class IncomeController
	extends BaseController<Income>
	implements IIncomeController
{
	listForSelfCustomer(params?: ListFilter<Income>): Promise<Paging<Income>> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/customer-self`, {
				params,
			})
			.then((res) => {
				return res.data;
			});
	}
	getFinanceForSelfCustomer(): Promise<Finance> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/finance-customer-self`)
			.then((res) => {
				return res.data;
			});
	}
	getFinanceByCustomerId(id: string): Promise<Finance> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/finance/${id}`)
			.then((res) => {
				return res.data;
			});
	}
	listByCustomerId(
		params?: ListFilter<Income> & { customerId: string }
	): Promise<Paging<Income>> {
		return {} as any;
	}
	addIncomeByAdmin(params: Income): Promise<Income> {
		return this.client
			.post(`${this.serviceURL}/${this.basePath}/add-by-admin`, params)
			.then((res) => {
				return res?.data;
			});
	}
}
