import { IGiftCardController } from "../model/base-gift-card/controller/IGiftCardController";
import { ListFilter, Paging } from "luong-base-model";
import { GiftCard } from "../model/base-gift-card/model/GiftCard";
import { BaseController } from "./BaseController";

export class GiftCardController
	extends BaseController<GiftCard>
	implements IGiftCardController {
	listForCustomer(params: ListFilter<GiftCard>): Promise<Paging<GiftCard>> {
		params = { ...params, sort: super.convertSort(params.sort) };
		params = {
			...params,
			searchFields: super.convertSearch(params.searchFields) as any,
		};
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/list-for-customer`, {
				params: params,
			})
			.then((res) => {
				return res.data;
			});
	}
}
