import { BaseModel } from 'luong-base-model';
import { Finance } from './Finance';
import { User } from './User';

export class Customer extends BaseModel {
  fullName?: string;

  email?: string;

  phoneNumber?: string;

  desc?: string;

  userId?: string;

  user?: User;

  typeCustomer?: ETypeCustomer;

  finance?: Finance;
}

export enum ETypeCustomer {
  TRUST = 'TRUST',
  NEW = 'NEW',
}

export function getStringETypeCustomer(type: ETypeCustomer): string {
  switch (type) {
    case ETypeCustomer.TRUST:
      return 'Khách hàng tin tưởng';
    case ETypeCustomer.NEW:
      return 'Khách hàng mới';
  }
}
