import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { formatterMoney } from '../../helpers/StringHelper';
import { GiftCard } from '../../model/base-gift-card/model/GiftCard';
import { OrderDetail } from '../../model/base-gift-card/model/OrderDetail';

const useStyle = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		background: "white",
		// borderRadius: theme.spacing(1),
        minWidth : 300
	},
    itemInfo : {
        borderBottom : `1px solid ${theme.palette.primary.main}`,
        padding : theme.spacing(1),
        margin : theme.spacing(1)
    }
}));
type Props  ={
    item : OrderDetail[]
}
export default function DetailOrderPopup(props : Props) {
    const classes = useStyle();
    const [detail, setDetail] = useState<GiftCard | null>()
    useEffect(() => {
        if(props.item.length>0){
            setDetail(props.item[0].giftCard)
        }else{
            setDetail(null)
        }
    }, [props])
    return (
        <Grid className = {classes.root} container justify = "center">
            <Grid xs = {12}>
                <Typography align = "center" variant = "h5" >Chi tiết</Typography>
            </Grid>
            <Grid xs= {5} className = {classes.itemInfo}>
                <Typography color = "primary">Mã thẻ</Typography>
                <Typography>{detail?.codeCard} </Typography>
            </Grid>
            <Grid xs= {5} className = {classes.itemInfo}>
                <Typography color = "primary">Mã pin</Typography>
                <Typography> {detail?.codePin} </Typography>
            </Grid>
            <Grid xs= {5} className = {classes.itemInfo}>
                <Typography color = "primary">Trị giá</Typography>
                <Typography> {formatterMoney.format(detail?.denomination|| 0)} </Typography>
            </Grid>

        </Grid>
    )
}
