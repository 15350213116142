import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import { AiFillDelete } from "react-icons/ai";

const useStyle = makeStyles((theme) => ({
	iconDelete: {
		color: theme.palette.error.main,
		transition: "0.3s",
		"&:hover": {
			background: theme.palette.error.main,
			color: "white",
		},
	},
}));
type Props<T> = {
	action: (item: T) => void;
	item: T;
};
export default function CellComponentDelete<T>(props: Props<T>) {
	const classes = useStyle();
	return (
		<Grid>
			<Tooltip title="Delete">
				<IconButton
					className={classes.iconDelete}
					onClick={(e) => props.action(props.item)}
				>
					<AiFillDelete />
				</IconButton>
			</Tooltip>
		</Grid>
	);
}
