/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import * as Yup from "yup";
import {
	Customer,
	ETypeCustomer,
	getStringETypeCustomer,
} from "../../model/base-gift-card/model/Customer";
import { useGlobalStyles } from "../../style/GlobalStyle";
import BaseDialog from "../genaral-component/BaseDialog";
import PhoneNumberInput from "../genaral-component/PhoneNumberInput";
import SelectBox from "../genaral-component/SelectBox";

type Props = {
	isDisplay: boolean;
	item: Customer;
	onEdit: (item: Customer) => void;
	onCancel: () => void;
};
const validate = Yup.object({
	desc: Yup.string().max(300, "Chữ không được quá 300 kí tự").nullable(),
	email: Yup.string()
		.email("Không đúng định dạng email")
		.max(100, "Chữ không được quá 100 kí tự")
		.nullable(),
	fullName: Yup.string()
		.max(100, "Chữ không được quá 100 kí tự")
		.required("Không được để trống !!")
		.trim()
		.nullable(),
	userName: Yup.string()
		.max(100, "Chữ không được quá 100 kí tự")
		.required("Không được để trống !!")
		.trim()
		.nullable(),
});

export default function PopupCustomer(props: Props) {
	const formik = useFormik<
		Customer & { password?: string; userName?: string }
	>({
		initialValues: {} as Customer,
		validationSchema: validate,
		onSubmit: () => {
			props.onEdit({
				...formik.values,
				user: {
					...formik.values,
					userName: formik.values.userName,
					password: formik.values.password,
				},
			});
		},
	});
	const onSubmit = () => {
		formik.handleSubmit();
		const touch = {
			..._.mapValues(new Customer(), () => true),
			userName: true,
			password: true,
		};
		console.log(touch);

		formik.setTouched(touch);
	};

	const createPassword = () => {
		return [...Array(10)]
			.map((i) => (~~(Math.random() * 36)).toString(36))
			.join("");
	};
	useEffect(() => {
		if (props.isDisplay) {
			console.log(props.item);
			formik.setValues(
				_.cloneDeep({
					...props.item,
					userName: props.item?.user?.userName || "",
					password: props.item?.user?.password || createPassword(),
					typeCustomer: props.item.typeCustomer || ETypeCustomer.NEW,
				})
			);
			formik.setTouched(_.mapValues(new Customer(), () => false));
		}
	}, [props]);
	const globalStyles = useGlobalStyles();
	return (
		<Grid>
			<BaseDialog
				isDisplay={props.isDisplay}
				onCancel={props.onCancel}
				onClickConfirm={() => {
					onSubmit();
				}}
				title="Khách hàng"
			>
				<Grid container direction="column" justify="space-around">
					<Grid>
						<TextField
							value={formik.values.fullName}
							helperText={
								formik.touched.fullName &&
								formik.errors.fullName
							}
							name="fullName"
							onChange={formik.handleChange}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Họ & Tên"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.desc}
							helperText={
								formik.touched.desc && formik.errors.desc
							}
							variant="outlined"
							name="desc"
							onChange={formik.handleChange}
							fullWidth
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							label="Mô tả"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.email}
							helperText={
								formik.touched.email && formik.errors.email
							}
							variant="outlined"
							name="email"
							onChange={formik.handleChange}
							fullWidth
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							label="Địa chỉ email"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.userName}
							// helperText={
							// 	formik.touched[`username` as any] && formik.errors.user['username']
							// }
							variant="outlined"
							onChange={(e) => {
								formik.setValues({
									...formik.values,
									userName: e.target.value,
								});
							}}
							fullWidth
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							label="Tên tài khoản"
							helperText={
								formik.touched.userName &&
								formik.errors.userName
							}
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.password}
							// helperText={
							// 	formik.touched[`username` as any] && formik.errors.user['username']
							// }
							variant="outlined"
							fullWidth
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								endAdornment: (
									<Button
										variant="contained"
										color="primary"
										onClick={(e) => {
											formik.setValues({
												...formik.values,
												password: createPassword(),
											});
										}}
									>
										{" "}
										reset
									</Button>
								),
							}}
							label="Mật khẩu"
						></TextField>
					</Grid>

					<Grid>
						<PhoneNumberInput
							onChange={(phone) => {
								formik.setValues({
									...formik.values,
									phoneNumber: phone,
								});
							}}
							value={formik.values.phoneNumber || "+84"}
							textHelper={
								formik.touched.email && formik.errors.email
							}
						/>
					</Grid>

					<Grid>
						<SelectBox
							className={clsx(globalStyles.mt2, globalStyles.mb4)}
							variant="outlined"
							fullWidth
							value={formik.values.typeCustomer}
							data={Object.keys(ETypeCustomer)}
							onChange={(value: any) => {
								formik.setValues({
									...formik.values,
									typeCustomer: value,
								});
							}}
							labelOption={(label) =>
								getStringETypeCustomer(label)
							}
							valueOption={(value) => value}
						/>
					</Grid>
				</Grid>
			</BaseDialog>
		</Grid>
	);
}
