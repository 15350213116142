/* eslint-disable jsx-a11y/alt-text */
import {
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { BiCommentError, BiError } from "react-icons/bi";
import { BsPen } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { formatterMoney } from "../../helpers/StringHelper";
import { GiftCardUser } from "../../model/base-gift-card/model/GiftCardUser";
import { EStatusOrder } from "../../model/base-gift-card/model/Order";
import { Dispatch } from "../../rematch/store";
import { useGlobalStyles } from "../../style/GlobalStyle";
import theme from "../../theme/MuiTheme";
import PrettoSlider from "../genaral-component/PrettoSlider";
import PrettoSliderShowValue from "../genaral-component/PrettoSliderShowValue";
const useStyle = makeStyles((theme) => ({
	root: {
		width: 370,
		height: 240,
		border: `1px solid ${theme.palette.grey[200]}`,
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
		cursor: "pointer",
	},
	frLogo: {
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
		background: theme.palette.background.default,
		overflow: "hidden",
		height: 40,
		width: 40,
	},
	logoGiftImg: {
		height: "100%",
	},
	frGiftCode: {
		background: theme.palette.background.default,
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
	},
	textGiftCode: {
		fontWeight: 550,
	},
	iconCopy: {
		padding: 0,
		margin: 0,
	},
	iconDesc: {
		padding: 0,
		margin: 0,
		marginRight: 10,
	},
}));
type Props = {
	item: GiftCardUser;
	onClickSelect: (item: GiftCardUser) => void;
	onChangeUpdate: (item: GiftCardUser) => void;
	onEditDesc?: (item: GiftCardUser) => void;
	onCopy?: (item: GiftCardUser) => void;
	fullWith?: boolean;
	isSelect: boolean;
};
export default function GiftCardItemPartner(props: Props) {
	const classes = useStyle();
	const dispath = useDispatch<Dispatch>();
	const globalStyle = useGlobalStyles();
	const getStringStatus = (status: EStatusOrder): string => {
		if (status === EStatusOrder.COMPLETE) return "Đã thanh toán";
		else if (status === EStatusOrder.WAITINGCONFIRM)
			return "Đang chờ xác nhận";
		else if (status === EStatusOrder.IN_DEBT) return "Chưa thanh toán";
		else return "Lỗi trạng thái";
	};
	const getIconStatus = (status: EStatusOrder): React.ReactElement => {
		if (status === EStatusOrder.COMPLETE)
			return (
				<TiTick
					style={{
						color: theme.palette.success.main,
					}}
				/>
			);
		else if (status === EStatusOrder.WAITINGCONFIRM)
			return (
				<RiErrorWarningLine
					style={{
						color: theme.palette.warning.main,
					}}
				/>
			);
		else if (status === EStatusOrder.IN_DEBT)
			return (
				<BiError
					style={{
						color: theme.palette.error.main,
					}}
				/>
			);
		else return <BiCommentError />;
	};
	return (
		<Grid
			container
			className={clsx(classes.root)}
			onClick={() => props.onClickSelect(props.item)}
			style={{
				background: props.isSelect ? "rgb(67, 221, 230)" : "none",
				width: props.fullWith ? "100%" : "none",
			}}
		>
			<Grid container className={clsx(globalStyle.pp1)}>
				<Grid container justify="space-between">
					<Grid>
						<Grid container>
							<Grid className={classes.frLogo}>
								<img
									src="/image/gift.png"
									className={classes.logoGiftImg}
								></img>
							</Grid>
							<Grid className={clsx(globalStyle.pl1)}>
								<Grid
									container
									direction="column"
									justify="space-between"
								>
									<Grid>
										<Typography>
											{props.item.giftCard?.brand?.name}
										</Typography>
									</Grid>
									<Grid>
										<Typography
											variant="caption"
											color="secondary"
										>
											Đã mua :
											{formatterMoney.format(
												props.item.order?.totalPrice ||
													0
											)}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid>
						<Grid>
							<FormControlLabel
								onClick={(e) => {
									e.stopPropagation();
								}}
								style={{
									padding: 0,
								}}
								control={
									<Checkbox
										checked={props.item.isUsed || false}
										name="gilad"
									/>
								}
								onChange={(e) => {
									e.stopPropagation();
									props.onChangeUpdate({
										...props.item,
										isUsed: !props.item.isUsed,
										remain: 0,
									});
								}}
								label={
									<Typography variant="caption">
										Đã sử dụng
									</Typography>
								}
							/>
						</Grid>
						<Grid>
							<Typography variant="caption">
								{moment(props.item.createdAt).format(
									"DD/MM/YYYY hh:mm"
								)}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container className={clsx(globalStyle.pp1)}>
				<Grid
					container
					justify="space-between"
					alignContent="center"
					alignItems={"center"}
					className={clsx(classes.frGiftCode)}
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<Grid>
						<Typography className={clsx(classes.textGiftCode)}>
							{props.item.giftCard?.codeCard}
						</Typography>
					</Grid>
					<Grid>
						{props.onEditDesc && (
							<Tooltip title="Ghi chú" aria-label="add">
								<IconButton
									className={classes.iconDesc}
									onClick={(e) => {
										e.stopPropagation();
										props.onEditDesc &&
											props.onEditDesc(props.item);
									}}
								>
									<BsPen />
								</IconButton>
							</Tooltip>
						)}

						<Tooltip title="Copy" aria-label="add">
							<IconButton
								className={classes.iconCopy}
								onClick={(e) => {
									e.stopPropagation();
									navigator.clipboard
										.writeText(
											props.item.giftCard?.codeCard || ""
										)
										.then((res) => {
											dispath.notification.success(
												"Copy thành công"
											);
										})
										.catch((err) => {
											dispath.notification.error("lỗi");
										});
								}}
							>
								<MdContentCopy />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid xs={12} container className={clsx(globalStyle.pp1)}>
					<Grid container justify="space-between">
						<Grid>
							<Typography variant="caption">
								Mã pin: {props.item.giftCard?.codePin || ""}
							</Typography>
						</Grid>
						<Grid>
							<Grid container>
								<Typography variant="caption" color="initial">
									{getStringStatus(
										props.item.order?.status as EStatusOrder
									)}
								</Typography>
								{getIconStatus(
									props.item.order?.status as EStatusOrder
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={12} container className={clsx(globalStyle.pp1)}>
					<PrettoSliderShowValue
						total={props.item.giftCard?.denomination || 0}
						value={props.item.remain || 0}
					></PrettoSliderShowValue>
				</Grid>
			</Grid>
		</Grid>
	);
}
