import {
	Grid,
	makeStyles,
	Slider,
	Typography,
	withStyles,
} from "@material-ui/core";
import React from "react";
import { formatterMoney } from "../../helpers/StringHelper";

const MyPrettoSlider = withStyles((theme) => ({
	root: {
		// color: theme.palette.primary.main,
		// height: 10,
		marginTop: -(34 / 2),
	},
	thumb: {
		height: 0,
		width: 0,
		backgroundColor: "#fff",
		// border: "2px solid currentColor",
		// marginTop: -41,
		marginLeft: -12,
		"&:focus, &:hover, &$active": {
			boxShadow: "inherit",
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 4px)",
	},
	track: {
		height: 34,
		borderRadius: 4,
	},
	rail: {
		height: 34,
		borderRadius: 4,
	},
}))(Slider);
type Props = {
	total?: number;
	value: number;
};
const useStyle = makeStyles((theme) => ({
	root: {
		position: "relative",
		height: 33,
		width: "100%",
	},
	typography: {
		position: "absolute",
		zIndex: 2,
		left: 0,
		top: 0,
		flex: 1,
		width: "100%",
		// color : 'white',
		height: 34,
	},
	slider: {
		position: "absolute",
		zIndex: 1,
		left: 0,
		top: 1,
		width: "100%",
	},
}));
export default function PrettoSliderShowValue(props: Props) {
	const classes = useStyle();
	const value = (props.value * 100) / (props.total || 100);
	return (
		<Grid className={classes.root}>
			<Grid
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.typography}
			>
				<Typography variant="caption">
					{formatterMoney.format(props.value)}/
					{formatterMoney.format(props.total || 0)} (Giá trị sử dụng)
				</Typography>
			</Grid>
			<MyPrettoSlider
				className={classes.slider}
				value={value}
				color="primary"
			></MyPrettoSlider>
		</Grid>
	);
}
