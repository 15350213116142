import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { appClient, userController } from "../controller";
import { InfoMe } from "../model/base-gift-card/controller/IUserController";
import { Customer } from "../model/base-gift-card/model/Customer";

export type AuthenModel = {
	role?: string;
	jwt?: string;
	info?: InfoMe;
	isGet?: boolean;
};

export const authen = createModel<RootModel>()({
	state: {
		isAuthenticated: false,
		isAuthor: false,
		jwt: "",
	} as AuthenModel,
	reducers: {
		update: (state, authen: AuthenModel) => {
			return {
				...authen,
			};
		},
	},
	effects: (dispatch) => {
		const { authen } = dispatch;
		return {
			async login(payload: AuthenModel): Promise<any> {
				localStorage.setItem("jwt", payload.jwt || "");
				return authen.update({
					...payload,
					isGet: true,
					role: payload.info?.role,
				});
			},
			async logOut() {
				appClient.defaults.headers["authorization"] = "";
				localStorage.setItem("jwt", "");
				authen.update({
					role: "",
					info: {} as any,
					isGet: true,
					jwt: "",
				});
			},
			async getMe(): Promise<any> {
				const jwt = localStorage.getItem("jwt");
				appClient.defaults.headers["authorization"] = jwt;
				userController
					.getMe()
					.then((res) => {
						return authen.update({
							info: res,
							role: res.role,
							jwt: res.jwt,
							isGet: true,
						});
					})
					.catch((err) => {
						authen.update({
							role: "",
							info: {} as any,
							jwt: "",
							isGet: true,
						});
					});
			},
		};
	},
});
