import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
	root: {
		minHeight: "100vh",
		minWidth: "100vh",
		// background: "linear-gradient(45deg, #ffaf00, #bb02ff)",
		background: "black",
		backgroundAttachment: "fixed",
	},
	container: {
		background: "white",
		padding: theme.spacing(8),
	},
	containerAction: {
		borderTop: `2px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(0),
	},
}));
export default function GiftCardDetail() {
	const classes = useStyle();
	return (
		<Grid
			container
			alignItems="center"
			justify="center"
			className={classes.root}
		>
			{/* <Grid xs={12}>
				<Button color="primary">-</Button>
			</Grid> */}
			<Grid
				container
				alignItems="flex-start"
				className={classes.container}
				md={8}
				spacing={3}
			>
				<Grid item md={6}>
					<Grid xs={12}>
						{/* <Typography variant="h3"> Hãng : </Typography> */}
						<Typography variant="h3"> Amazon</Typography>
					</Grid>
					<Grid xs={12}>
						<Typography variant="h5" color="primary">
							100,000d
						</Typography>
					</Grid>
				</Grid>
				<Grid item md={6}>
					<Grid container alignItems="flex-start">
						<Typography>
							Mô tả : These gorgeous, modern templates are based
							on flexible designs that adjust to different types
							of screen resolutions. This means that every single
							responsive template available on our website will be
							adaptable to any device people use to enter the
							website that is based on this template. Technically
							we achieve this with the help of CSS3 media queries,
							so it’s all good for most of the browsers and
							devices you may use to access the website. You may
							even see how template layout switches by playing
							with the browser window width – adjust it while
							viewing any demo to see how the layout changes.
							However, to our readers who love old junk browsers –
							or should we politely call these browsers ‘vintage’
							– we must say that the the dynamic screen size
							adjuster won’t work in the old versions of Internet
							Explorer. We assume that people browsing websites
							based on these templates will be browsing mobile web
							with Safari, Firefox, or Opera Mini and other mobile
							browsers which have no problems with CSS3.
						</Typography>
					</Grid>
				</Grid>
				<Grid className={classes.containerAction} container>
					<Button variant="outlined" color="primary">
						Mua ghi nơ{" "}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
