import {
	InfoMe,
	IUserController,
	PropsChangePassword,
} from "../model/base-gift-card/controller/IUserController";
import { Customer } from "../model/base-gift-card/model/Customer";
import { User } from "../model/base-gift-card/model/User";
import { BaseController } from "./BaseController";

export class UserController
	extends BaseController<User>
	implements IUserController {
	changePassword(params: PropsChangePassword): Promise<User> {
		return this.client
			.post(`${this.serviceURL}/${this.basePath}/change-password`, params)
			.then((res) => {
				return res.data;
			});
	}
	login(params: {
		username: string;
		password: string;
	}): Promise<Customer & { jwt: string; role: string }> {
		return this.client
			.post(`${this.serviceURL}/${this.basePath}/login`, params)
			.then((res) => {
				return res.data;
			});
	}
	getMe(): Promise<InfoMe> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/getMe`)
			.then((res) => {
				return res.data;
			});
	}
}
