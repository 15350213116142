import { BaseModel } from 'luong-base-model';
import { Customer } from './Customer';

export class Income extends BaseModel {
  customerId?: string;

  customer?: Customer;

  amount?: number;

  incomeSource?: IncomeSource;
  incomeSourceMappingId?: string;
}

export enum IncomeSource {
  ADMIN = 'ADMIN',
  BUY_GIFT = 'BUY_GIFT',
  DEDUCT_BY_ADMIN = 'DEDUCT_BY_ADMIN',
  PAYMENT_DEBIT = 'PAYMENT_DEBIT',
}
