import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { formatterMoney } from "../../helpers/StringHelper";
import { GiftCard } from "../../model/base-gift-card/model/GiftCard";
import { useGlobalStyles } from "../../style/GlobalStyle";
import theme from "../../theme/MuiTheme";
import parse from "html-react-parser";
import { ETypeCustomer } from "../../model/base-gift-card/model/Customer";
import { ETypePayment } from "../../model/base-gift-card/controller/IOrderController";
import { ImCoinDollar } from "react-icons/im";
import { BiCoinStack } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import GiftCardItemPartner from "../partner/GiftCardItemPartner";
import { GiftCardUser } from "../../model/base-gift-card/model/GiftCardUser";
import { giftCardUserController } from "../../controller";
import { Income, IncomeSource } from "../../model/base-gift-card/model/Income";
import { IncomeHelper } from "../../helpers/IncomeHelper";
import moment from "moment";
import { handleWithPopupHook } from "../../helpers/HandleWithPopupHook";
type Props = {
	isDisplay: boolean;
	item: Income;
	onCancel: () => void;
	isAdmin: boolean;
};
const useStyle = makeStyles((theme) => ({
	root: {
		"& .MuiPaper-root": {
			backgroundColor: "#D9AFD9",
			backgroundImage: "linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%)",
		},
	},
	frInfoTransaction: {
		marginTop: theme.spacing(6),
		background: "white",
		borderRadius: theme.spacing(1),
		padding: theme.spacing(2),
	},
	iconTransaction: {
		color: theme.palette.error.main,
		fontSize: "2rem",
		padding: theme.spacing(2),
	},
	containerTransactionSuccess: {
		marginTop: theme.spacing(1),
		background: "rgba(131, 242, 161,0.5)",
		borderRadius: theme.spacing(1),
		padding: theme.spacing(1.4),
	},
	descTransaction: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(2),
	},
}));
export default function PopupIncomeDetail(props: Props) {
	const classes = useStyle();
	const globalStyles = useGlobalStyles();
	const [state, setState] = useState<{
		giftCardUser?: GiftCardUser;
	}>({});
	useEffect(() => {
		if (
			props.isDisplay &&
			props.item.incomeSource === IncomeSource.BUY_GIFT &&
			props.item.incomeSourceMappingId
		) {
			if (props.isAdmin) {
				giftCardUserController
					.getByOrderId({
						orderId: props.item.incomeSourceMappingId || "",
					})
					.then((res) =>
						setState({
							...state,
							giftCardUser: res,
						})
					);
			} else {
				giftCardUserController
					.getByOrderIdForCustomer({
						orderId: props.item.incomeSourceMappingId || "",
					})
					.then((res) =>
						setState({
							...state,
							giftCardUser: res,
						})
					);
			}
		} else {
			setState({
				...state,
				giftCardUser: undefined,
			});
		}
	}, [props]);
	return (
		<Dialog
			open={props.isDisplay}
			fullWidth={true}
			maxWidth={"sm"}
			className={classes.root}
		>
			<DialogTitle className={clsx(globalStyles.pp0, globalStyles.mm0)}>
				<Grid item xs={12}>
					{/* <Typography variant="h4" color="error" align="center">
						Cảnh báo
					</Typography> */}
				</Grid>
				<Box
					style={{
						position: "absolute",
						top: "1.5rem",
						right: "1.5rem",
					}}
				>
					<IconButton
						aria-label="close"
						onClick={() => {
							props.onCancel();
						}}
						style={{
							color: "white",
						}}
						color="inherit"
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Grid container direction="column">
					<Grid item xs={12}>
						{/* Thong tin giao dịch */}
						<Grid
							className={classes.frInfoTransaction}
							container
							alignContent="center"
							alignItems="center"
						>
							<Grid>
								<BiCoinStack
									className={classes.iconTransaction}
								/>
							</Grid>

							<Grid>
								<Typography variant="overline">
									{IncomeHelper.getLabelFromIncome(
										props.item?.incomeSource as IncomeSource
									)}
								</Typography>
								<Typography variant="h5">
									{formatterMoney.format(
										props.item.amount || 0
									)}
								</Typography>
							</Grid>
							<Grid
								container
								className={classes.containerTransactionSuccess}
							>
								<TiTick />
								<Typography>Giao dịch thành công</Typography>
							</Grid>
							<Grid container>
								<Grid
									className={classes.descTransaction}
									xs={12}
									container
									justify="space-between"
									style={{
										justifyItems: "space-between",
									}}
								>
									<Typography variant="subtitle1">
										Thời gian
									</Typography>
									<Typography variant="body2">
										{moment(props.item.createdAt).format(
											"hh:mm DD/MM/YYYY"
										)}
									</Typography>
								</Grid>
								<Grid
									className={classes.descTransaction}
									xs={12}
									container
									justify="space-between"
									style={{
										justifyItems: "space-between",
									}}
								>
									<Typography variant="subtitle1">
										Nguồn tiền
									</Typography>
									<Typography variant="body2">
										{IncomeHelper.getLabelFromIncome(
											props.item
												?.incomeSource as IncomeSource
										)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						{/* thông tin thẻ */}

						<Grid
							container
							className={classes.frInfoTransaction}
							style={{
								padding: 0,
							}}
						>
							{state.giftCardUser && (
								<GiftCardItemPartner
									fullWith={true}
									item={state.giftCardUser}
									// onEditDesc={crudGiftCardUser.onShowPopup}
									isSelect={false}
									onChangeUpdate={() => {}}
									onClickSelect={() => {}}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
