import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React from "react";
import { formatterMoney } from "../../helpers/StringHelper";
import { GiftCard } from "../../model/base-gift-card/model/GiftCard";
import { useGlobalStyles } from "../../style/GlobalStyle";
import theme from "../../theme/MuiTheme";
import parse from "html-react-parser";
import { ETypeCustomer } from "../../model/base-gift-card/model/Customer";
import { ETypePayment } from "../../model/base-gift-card/controller/IOrderController";
import { ImCoinDollar } from "react-icons/im";
import { BiCoinStack } from "react-icons/bi";
type Props = {
	isDisplay: boolean;
	onCancel: () => void;
	totalMoney: number;
	typeCustomer: ETypeCustomer;
	onPayment: (type: ETypePayment) => void;
	labelButtonPaymentAdmin?: string;
	labelButtonPaymentCoin?: string;
};
const useStyle = makeStyles((theme) => ({
	frPercentsSale: {
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(2),
		borderRadius: "50%",
		height: 80,
		width: 80,
	},
	textPercentsSale: {
		color: "white",
		fontWeight: 650,
	},
	frDesc: {
		maxHeight: 200,
		overflow: "auto",
		"&::-webkit-scrollbar": {
			width: "0.5em",
			transition: "1s",
		},
		"&::webkit-scrollbar-track": {
			borderRadius: 50,
			transition: "1s",
			WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "darkgrey",
			borderRadius: 5,
		},
	},
	textPriceDefault: {
		textDecoration: "line-through",
	},
}));
export default function PopupOptionPayment(props: Props) {
	const classes = useStyle();
	const globalStyles = useGlobalStyles();
	return (
		<Dialog open={props.isDisplay} fullWidth={true} maxWidth={"sm"}>
			<DialogTitle className={clsx(globalStyles.pp0, globalStyles.mm0)}>
				<Grid item xs={12}>
					{/* <Typography variant="h4" color="error" align="center">
						Cảnh báo
					</Typography> */}
				</Grid>
				<Box
					style={{
						position: "absolute",
						top: "1.5rem",
						right: "1.5rem",
					}}
				>
					<IconButton
						aria-label="close"
						onClick={() => {
							props.onCancel();
						}}
						color="inherit"
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent>
				<Grid container direction="column">
					<Grid item xs={12}>
						<Grid
							container
							justify="center"
							className={clsx(globalStyles.pp1)}
						>
							<BiCoinStack
								style={{
									fontSize: "6rem",
									color: theme.palette.error.main,
									fontWeight: 200,
								}}
							/>
						</Grid>
						<Grid
							container
							justify="center"
							className={clsx(globalStyles.pp1)}
						>
							<Typography
								// className={classes.title}
								variant="h4"
								align="center"
								// color="textPrimary"
							>
								{formatterMoney.format(props.totalMoney)}
							</Typography>
						</Grid>
						<Grid
							container
							className={clsx(globalStyles.pp1)}
							justify="center"
						>
							<Typography
								// className={classes.title}
								variant="subtitle1"
								align="center"
								// color="textPrimary"
							>
								{"Số tiền phải thanh toán"}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Grid item xs={12}>
					<Grid
						item
						container
						xs={12}
						direction="row"
						justify="space-evenly"
						alignItems="center"
					>
						<Button
							// className={globalStyles.buttonOutline}
							startIcon={<CloseIcon />}
							variant="outlined"
							size="medium"
							color={"primary"}
							onClick={() => {
								props.onPayment(ETypePayment.IN_DEBT);
							}}
						>
							{props.labelButtonPaymentAdmin || "Gửi Admin"}
						</Button>

						<Button
							variant="contained"
							className={globalStyles.buttonAlert}
							// size="small"
							startIcon={<ImCoinDollar />}
							color="primary"
							onClick={() => {
								props.onPayment(ETypePayment.WITH_COIN);
							}}
						>
							{props.labelButtonPaymentCoin || "Sử dụng coin"}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
