import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	makeStyles,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React from "react";
import { formatterMoney } from "../../helpers/StringHelper";
import { GiftCard } from "../../model/base-gift-card/model/GiftCard";
import { useGlobalStyles } from "../../style/GlobalStyle";
import theme from "../../theme/MuiTheme";
import parse from "html-react-parser";
import { ETypeCustomer } from "../../model/base-gift-card/model/Customer";
import { ETypePayment } from "../../model/base-gift-card/controller/IOrderController";
type Props = {
	isDisplay: boolean;
	onCancel: () => void;
	item: GiftCard;
	typeCustomer: ETypeCustomer;
	onOrder: (item: GiftCard & { typePayment: ETypePayment }) => void;
};
const useStyle = makeStyles((theme) => ({
	frPercentsSale: {
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(2),
		borderRadius: "50%",
		height: 80,
		width: 80,
	},
	textPercentsSale: {
		color: "white",
		fontWeight: 650,
	},
	frDesc: {
		maxHeight: 200,
		overflow: "auto",
		"&::-webkit-scrollbar": {
			width: "0.5em",
			transition: "1s",
		},
		"&::webkit-scrollbar-track": {
			borderRadius: 50,
			transition: "1s",
			WebkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "darkgrey",
			borderRadius: 5,
		},
	},
	textPriceDefault: {
		textDecoration: "line-through",
	},
}));
export default function PopupGiftCardDetail(props: Props) {
	const classes = useStyle();
	const globalStyles = useGlobalStyles();
	return (
		<Dialog open={props.isDisplay} fullWidth>
			<Grid>
				<DialogTitle
					style={{
						padding: theme.spacing(2),
					}}
				>
					<Box
						style={{
							position: "absolute",
							top: "1.5rem",
							left: "1.5rem",
						}}
					>
						<Grid
							className={classes.frPercentsSale}
							container
							alignContent="center"
							justify="center"
						>
							{props.item.percentDiscount && (
								<Typography
									className={classes.textPercentsSale}
								>
									{props.item.percentDiscount}%
								</Typography>
							)}
						</Grid>
					</Box>
					<Box
						style={{
							position: "absolute",
							top: "1.5rem",
							right: "1.5rem",
						}}
					>
						<IconButton aria-label="close" onClick={props.onCancel}>
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle>
			</Grid>
			<DialogContent>
				<Grid
					container
					xs={12}
					direction="column"
					className={clsx(globalStyles.mt1)}
				>
					<Grid className={globalStyles.pt2}>
						<Typography variant="h5" align="center" color="primary">
							{props.item?.brand?.name}
						</Typography>
					</Grid>
					<Grid className={globalStyles.pt2}>
						<Typography variant="h5" color="primary">
							Giá trị :{" "}
							{formatterMoney.format(
								props.item?.denomination || 0
							)}
						</Typography>
					</Grid>
					<Grid className={clsx(classes.frDesc, globalStyles.mt2)}>
						<Typography variant="caption">
							{parse(props.item?.brand?.desc || "")}
						</Typography>
					</Grid>
					<Grid
						className={globalStyles.pt2}
						container
						justify="space-around"
					>
						<Typography
							variant="h5"
							className={classes.textPriceDefault}
						>
							{formatterMoney.format(
								props.item.denomination || 0
							)}
						</Typography>
						<Typography variant="h5" color="primary">
							{formatterMoney.format(props.item.priceSale || 0)}
						</Typography>
					</Grid>
					<Grid
						container
						justify="space-between"
						className={globalStyles.pt2}
					>
						{props.typeCustomer === ETypeCustomer.TRUST && (
							<Button
								color="primary"
								variant="outlined"
								onClick={(e) =>
									props.onOrder({
										...props.item,
										typePayment: ETypePayment.IN_DEBT,
									})
								}
							>
								Mua trả sau
							</Button>
						)}
						<Button
							color="primary"
							variant="contained"
							onClick={(e) =>
								props.onOrder({
									...props.item,
									typePayment: ETypePayment.WITH_COIN,
								})
							}
						>
							Mua bằng coin
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
