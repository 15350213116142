import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CellComponentDelete from "../component/genaral-component/CellComponentDelete";
import CellComponentEdit from "../component/genaral-component/CellComponentEdit";
import PopUpConfirm from "../component/genaral-component/PopupConfirm";
import TableCustom, { ColumnTable } from "../component/genaral-component/Table";
import PopupBrand from "../component/gift-card/PopupBrand";
import { brandController } from "../controller";
import { useCrudHook } from "../helpers/CrudHook";
import { Brand } from "../model/base-gift-card/model/Brand";
import theme from "../theme/MuiTheme";

export default function BrandManager() {
	const [column, setColumn] = useState<ColumnTable<Brand>[]>([
		{
			id: "name",
			label: "Tên loại gift",
		},
		{
			id: "action" as any,
			label: "Hành động",
			acceptSearch: false,
			isSort: false,
		},
	]);
	const crud = useCrudHook<Brand>({
		controller: brandController,
		initQuery: { pageSize: 5 },
	});
	useEffect(() => {}, []);
	return (
		<Grid
			style = {{
				padding : theme.spacing(1)
			}}
		>
			<PopupBrand
				isDisplay={crud.isShowPopup}
				item={crud.itemSelected}
				onCancel={crud.onCancelPopup}
				onEdit={crud.onSave}
			/>
			<PopUpConfirm
				isDisplay={crud.isShowConfirm}
				onCancel={crud.onCancelConfirm}
				onConfirm={() => crud.onDelete(crud.itemSelected)}
			/>
			<Grid
				style={{
					background: theme.palette.background.paper,
					padding: theme.spacing(6),
				}}
				container
			>
				<Grid item xs={12} container justify="space-between">
					<Typography variant="h4">Hãng</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={(e) => crud.onShowPopup({})}
					>
						Thêm mới
					</Button>
				</Grid>
				<Grid item xs={12}>
					<TableCustom<Brand>
						column={column}
						data={crud.pagingList}
						onChangeColumn={(newColumn) => setColumn(newColumn)}
						onQuery={crud.setQuery}
						isShowHighlightText={true}
						query={crud.query}
						onCustomerCell={(item) => {
							return {
								...item,
								action: (
									<Grid container>
										{
											<CellComponentDelete
												action={crud.onConfirm}
												item={item}
											/>
										}
										{
											<CellComponentEdit
												action={crud.onShowPopup}
												item={item}
											/>
										}
									</Grid>
								),
							};
						}}
					></TableCustom>
				</Grid>
			</Grid>
		</Grid>
	);
}
