import { Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Chart as ChartGoogle } from "react-google-charts";
import DatePicker from "../component/genaral-component/DatePicker";
import StatisticsItem from "../component/statictis/StatisticsItem";
import StatisticsOrderAmountItem from "../component/statictis/StatisticsOrderAmountItem";
import { orderController } from "../controller";
import { formatterMoney } from "../helpers/StringHelper";
import {
	EIntervalStatistic,
	FilterStatistic,
	StatisticAmount,
	StatisticRevenue,
	StatisticStatusOrder,
} from "../model/base-gift-card/controller/IOrderController";
import { useGlobalStyles } from "../style/GlobalStyle";
import theme from "../theme/MuiTheme";

const useStyle = makeStyles((theme) => ({
	root: {
		padding: 10,
	},
	statisticLeft: {
		background: theme.palette.background.paper,
		// borderRadius: theme.spacing(1),
		padding: theme.spacing(3),
	},
	chart: {
		background: theme.palette.background.paper,
		// borderRadius: theme.spacing(1),
		// marginTop: theme.spacing(2),
		width: "100%",
	},
	frChart: {
		background: "white",
		padding: theme.spacing(5),
	},
}));

export default function Statistics() {
	const globalsStyle = useGlobalStyles();
	const [stateDate, setStateDate] = useState<FilterStatistic>({
		interval: EIntervalStatistic.DAY,
	});
	const [statisticOrder, setStatisticOrder] = useState<{
		amount: StatisticStatusOrder;
		revenue: StatisticStatusOrder;
		chartRevenue: any[];
		mountRevenue: any[];
	}>();
	const classes = useStyle();
	const getData = (filter: FilterStatistic) => {
		Promise.all([
			orderController.statisticStatusOrderPrice(filter), //
			orderController.statisticStatusOrderAmount(filter), //
			orderController.intervalRevenue(filter),
			orderController.intervalAmount(filter),
		]).then((_res) => {
			setStatisticOrder({
				amount: _res[0],
				revenue: _res[1],
				chartRevenue: [
					["x", "Tổng", "Đã thu", "Chưa Thu", "Chờ xác nhận"],
				].concat(
					_res[2].map((item) => [
						moment(item.date).format("DD-MM-YYYY"),
						item.total as any,
						item.totalComplete as any,
						item.totalInDebt as any,
						item.totalWaitingConfirm as any,
					])
				),
				mountRevenue: [
					["x", "Tổng", "Đã thu", "Chưa Thu", "Chờ xác nhận"],
				].concat(
					_res[3].map((item) => [
						moment(item.date).format("DD-MM-YYYY"),
						item.total as any,
						item.totalComplete as any,
						item.totalInDebt as any,
						item.totalWaitingConfirm as any,
					])
				),
			});
		});
	};
	useEffect(() => {
		getData(stateDate);
	}, [stateDate]);
	return (
		<Grid
			container
			style={{
				padding: theme.spacing(1),
				
			}}
		>
			<Grid
				container
				justify="flex-end"
			>
				<Grid
					container
					justify = "flex-end"
					style={{
						top: theme.spacing(1),
						background: "white",
						marginBottom : theme.spacing(1), 
						padding: theme.spacing(3),
					}}
				>
					<DatePicker
						onChangeDateRange={(date) => {
							setStateDate({
								...stateDate,
								from: date.startDate,
								to: date.endDate,
							});
						}}
						label={"Chọn ngày"}
					/>
				</Grid>
			</Grid>
			{/* <Typography variant={"h4"} gutterBottom>
				Dashboard
			</Typography> */}
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12}>
					<Grid>
						<Grid container spacing={3}>
							<Grid item xl={6} xs={12}>
								<ChartGoogle
									width={"100%"}
									height={"300px"}
									chartType="PieChart"
									loader={<div>Loading Chart</div>}
									data={[
										["Task", "Hours per Day"],
										[
											`Chưa thu (${formatterMoney.format(
												statisticOrder?.revenue
													?.totalInDebt || 0
											)})`,
											statisticOrder?.revenue
												?.totalInDebt || 0,
										],
										[
											`Đã thu (${formatterMoney.format(
												statisticOrder?.revenue
													?.totalComplete || 0
											)})`,
											statisticOrder?.revenue
												?.totalComplete || 0,
										],
										[
											`Chờ xác nhận (${formatterMoney.format(
												statisticOrder?.revenue
													?.totalWaitingConfirm || 0
											)})`,
											statisticOrder?.revenue
												?.totalWaitingConfirm || 0,
										],
										// totalWaitingConfirm
									]}
									options={{
										title: `Thống kê tiền (${formatterMoney.format(
											statisticOrder?.revenue
												?.total || 0
										)})`,
										// Just add this option
										pieHole: 0.4,
									}}
									rootProps={{ "data-testid": "3" }}
								/>
							</Grid>
							<Grid item xl={6} xs={12}>
							<ChartGoogle
									width={"100%"}
									height={"300px"}
									chartType="PieChart"
									loader={<div>Loading Chart</div>}
									data={[
										["Task", "Hours per Day"],
										[
											`Chưa thu (${statisticOrder?.amount
												?.totalInDebt || 0} )`,
											statisticOrder?.amount
												?.totalInDebt || 0,
										],
										[
											`Đã thu (${statisticOrder?.amount
												?.totalComplete || 0} )`,
											statisticOrder?.amount
												?.totalComplete || 0,
										],
										[
											`Chờ xác nhận (${statisticOrder?.amount
												?.totalWaitingConfirm || 0})`,
											statisticOrder?.amount
												?.totalWaitingConfirm || 0,
										],
										// totalWaitingConfirm
									]}
									options={{
										title: `Thống kê tiền (${statisticOrder?.amount
											?.total})`,
										// Just add this option
										pieHole: 0.4,
									}}
									rootProps={{ "data-testid": "3" }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container spacing={3}>
							<Grid item lg={12} md={12}>
								{/* <RecentGriftCard /> */}
								<Grid className={classes.frChart}>
									<Grid>
										<Typography variant="h5">
											Doanh thu
										</Typography>
									</Grid>
									<ChartGoogle
										width={"95%"}
										height={"400px"}
										chartType="AreaChart"
										loader={<div>Loading Chart</div>}
										data={statisticOrder?.chartRevenue}
										options={{
											hAxis: {
												title: "Ngày",
											},
											vAxis: {
												title: "Tổng tiền",
											},
											series: {
												1: { curveType: "function" },
											},
											animation: {
												duration: 500,
												easing: "out",
												startup: true,
											},
										}}
										rootProps={{ "data-testid": "2" }}
									/>
								</Grid>
							</Grid>
							<Grid item lg={12} md={12}>
								{/* <RecentGriftCard /> */}
								<Grid className={classes.frChart}>
									<Grid>
										<Typography variant="h5">
											Số lượng
										</Typography>
									</Grid>
									<ChartGoogle
										width={"95%"}
										height={"400px"}
										chartType="AreaChart"
										loader={<div>Loading Chart</div>}
										data={statisticOrder?.mountRevenue}
										options={{
											hAxis: {
												title: "Ngày",
											},
											vAxis: {
												title: "Tổng số lượng",
											},
											series: {
												1: { curveType: "function" },
											},
											animation: {
												duration: 500,
												easing: "out",
												startup: true,
											},
										}}
										rootProps={{ "data-testid": "2" }}
									/>
								</Grid>
							</Grid>
							{/* <Grid
								item
								lg={6}
								xs={12}
								container
								className={classes.frChart}
							>
								<Grid className={classes.chart} container>
									<Chart data={data}>
										<ArgumentAxis />
										<ValueAxis />
										<BarSeries
											valueField="val"
											argumentField="region"
										/>
										<Title text="World population" />
										<Animation />
									</Chart>
								</Grid>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
