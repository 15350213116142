import {
	Button,
	Checkbox,
	Chip,
	FormControl,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { AnySoaRecord } from "node:dns";
import React, { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { useDispatch } from "react-redux";
import CustomerDetailOrderPopup from "../component/customer/CustomerDetailOrderPopup";
import PopUpConfirm from "../component/genaral-component/PopupConfirm";
import TableCustom, { ColumnTable } from "../component/genaral-component/Table";
import PopupOptionPayment from "../component/income/PopupOptionPayment";
import DetailOrderPopup from "../component/order/DetailOrderPopup";
import { customerController, orderController } from "../controller";
import { useCrudHook } from "../helpers/CrudHook";
import { formatterMoney } from "../helpers/StringHelper";
import { ETypePayment } from "../model/base-gift-card/controller/IOrderController";
import {
	Customer,
	ETypeCustomer,
} from "../model/base-gift-card/model/Customer";
import { EStatusOrder, Order } from "../model/base-gift-card/model/Order";
import { OrderDetail } from "../model/base-gift-card/model/OrderDetail";
import { Dispatch } from "../rematch/store";
import { useGlobalStyles } from "../style/GlobalStyle";
import theme from "../theme/MuiTheme";

// const useStyle = makeStyles((theme) => ({
// 	iconConfirmPayment: {
// 		color: theme.palette.success.main,
// 		transition: "0.3s",
// 		"&:hover": {
// 			background: theme.palette.grey[300],
// 			color: "white",
// 		},
// 	},
// }));
export default function OrderManager() {
	// const classes = useStyle();
	const globalStyle = useGlobalStyles();
	const dispatch = useDispatch<Dispatch>();
	const [customers, setCustomers] = useState<Customer[]>([]);
	const [column, setColumn] = useState<ColumnTable<Order>[]>([
		{
			id: "check" as any,
			isSort: false,
			acceptSearch: false,
			label: "Chọn",
		},
		{
			id: "id",
			label: "Mã đơn hàng",
		},
		{
			id: "customer.email" as any,
			isSort: false,
			acceptSearch: false,
			label: "Email khách hàng",
		},
		{
			id: "totalPrice",
			label: "Tổng giá",
			acceptSearch: false,
			isSort: true,
		},
		{
			id: "createdAt",
			label: "Ngày mua",
			acceptSearch: false,
		},
		{
			id: "status",
			label: "Trạng thái",
			acceptSearch: false,
		},
	]);
	const crud = useCrudHook<Order>({
		controller: orderController,
		initQuery: { pageSize: 5 },
	});
	const [anchorElDetail, setAnchorElDetail] = React.useState<{
		ref: null | HTMLElement;
		dataCustomer: Customer | null | undefined;
		dataOrderDetail: OrderDetail[] | null | undefined;
	}>({
		ref: null,
		dataCustomer: null,
		dataOrderDetail: null,
	});

	const [state, setState] = useState<{
		listGiftCardSelected: Order[];
		listGiftCardSelectedMap: Map<string, Order>;
		isShowPopupConfirm: boolean;
		isShowPopupOptionPayment: boolean;
	}>({
		listGiftCardSelected: [],
		listGiftCardSelectedMap: new Map<string, Order>(),
		isShowPopupConfirm: false,
		isShowPopupOptionPayment: false,
	});

	const onClickSelect = (item: Order) => {
		if (item?.status !== EStatusOrder.WAITINGCONFIRM) return;
		const get = state?.listGiftCardSelectedMap.get(item.id || "");
		const list = state.listGiftCardSelectedMap;
		if (get) {
			list.delete(get.id || "");
		} else {
			if (
				state.listGiftCardSelected.length == 0 ||
				(state.listGiftCardSelected.length > 0 &&
					state.listGiftCardSelected[0].customerId == item.customerId)
			) {
				list.set(item.id || "", item);
			} else {
				dispatch.notification.error(
					"Bạn chỉ được chọn các đơn hàng của một khách hàng"
				);
			}
		}

		setState({
			...state,
			listGiftCardSelected: Array.from(list.values()),
			listGiftCardSelectedMap: list,
		});
	};

	const getTotalPrice = (): number => {
		let total = 0;
		state?.listGiftCardSelectedMap.forEach((item) => {
			total += item?.totalPrice || 0;
		});
		return total;
	};

	const onConfirmOrder = (typePayment: ETypePayment) => {
		const item: Order[] = state.listGiftCardSelected;
		if (item.length === 0) return;
		orderController
			.confirmComplete({
				orderId: item.map((item) => item.id || "") || [],
				typePayment,
			})
			.then((res) => {
				setState({
					...state,
					listGiftCardSelected: [],
					listGiftCardSelectedMap: new Map<string, Order>(),
					isShowPopupConfirm: false,
					isShowPopupOptionPayment: false,
				});
				crud.onRefreshList();
			});
	};

	const onCloseDetail = () => {
		setAnchorElDetail({
			dataCustomer: null,
			ref: null,
			dataOrderDetail: null,
		});
	};
	const onChangeSelectStatusOrder = (e: any) => {
		if (e.target.value !== "all") {
			crud.setQuery({
				...crud.query,
				filter: {
					...crud.query.filter,
					status: [e.target.value],
				},
			});
		} else {
			crud.setQuery({
				...crud.query,
				filter: {
					...crud.query.filter,
					status: undefined,
				},
			});
		}
	};

	const onChangeSelectCustomer = (value: any) => {
		if (value) {
			crud.setQuery({
				...crud.query,
				filter: {
					...crud.query.filter,
					customerId: [value.id || ""],
				},
			});
		} else {
			crud.setQuery({
				...crud.query,
				filter: {
					...crud.query.filter,
					customerId: undefined,
				},
			});
		}
	};

	const handleCancelPopupPaymet = () => {
		setState({
			...state,
			isShowPopupOptionPayment: false,
		});
	};

	const handleShowPopupPayment = () => {
		setState({
			...state,
			isShowPopupOptionPayment: true,
		});
	};

	useEffect(() => {
		customerController.find({}).then((res) => setCustomers(res));
	}, []);
	return (
		<Grid
			style={{
				padding: theme.spacing(1),
			}}
		>
			<PopupOptionPayment
				isDisplay={state.isShowPopupOptionPayment}
				onCancel={handleCancelPopupPaymet}
				typeCustomer={ETypeCustomer.TRUST}
				totalMoney={getTotalPrice()}
				onPayment={(type) => onConfirmOrder(type)}
				labelButtonPaymentAdmin={"Thanh toán (Quyền Admin)"}
				labelButtonPaymentCoin={"Với coin khách hàng"}
			/>
			<Menu
				id="simple-menu"
				anchorEl={anchorElDetail.ref}
				keepMounted
				open={Boolean(anchorElDetail.dataOrderDetail)}
				onClose={onCloseDetail}
			>
				<DetailOrderPopup item={anchorElDetail.dataOrderDetail || []} />
			</Menu>
			<Menu
				id="simple-menu"
				anchorEl={anchorElDetail.ref}
				keepMounted
				open={Boolean(anchorElDetail.dataCustomer)}
				onClose={onCloseDetail}
			>
				<CustomerDetailOrderPopup item={anchorElDetail.dataCustomer} />
			</Menu>
			<Grid
				style={{
					background: theme.palette.background.paper,
					padding: theme.spacing(6),
				}}
				container
			>
				<Grid item xs={12} container justify="space-between">
					<Typography variant="h4">Đơn hàng</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={(e) => crud.onShowPopup({})}
					>
						Thêm mới
					</Button>
				</Grid>
				<Grid item xs={12}>
					<TableCustom<Order>
						column={column}
						data={crud.pagingList}
						onChangeColumn={(newColumn) => setColumn(newColumn)}
						onQuery={crud.setQuery}
						isShowHighlightText={true}
						query={crud.query}
						otherFilter={
							<Grid>
								<Grid container justify="space-between">
									<FormControl variant="outlined">
										<Select
											defaultValue={"all"}
											onChange={(e: any) => {
												onChangeSelectStatusOrder(e);
											}}
										>
											<MenuItem value={"all"}>
												Tất cả
											</MenuItem>
											<MenuItem
												value={EStatusOrder.COMPLETE}
											>
												Đã hoàn thành
											</MenuItem>
											<MenuItem
												value={EStatusOrder.IN_DEBT}
											>
												Đang nợ
											</MenuItem>
											<MenuItem
												value={
													EStatusOrder.WAITINGCONFIRM
												}
											>
												Đang chờ xác nhận
											</MenuItem>
										</Select>
									</FormControl>
									<Autocomplete
										options={customers}
										getOptionLabel={(option) =>
											option.email || ""
										}
										style={{ width: 300 }}
										onChange={(e, value) => {
											onChangeSelectCustomer(value);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Khách hàng"
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid>
									{state.listGiftCardSelected.length !==
										0 && (
										<Button
											onClick={(e) =>
												handleShowPopupPayment()
											}
											className={globalStyle.mt3}
											variant="contained"
											color="primary"
										>
											Xác nhận :{" "}
											{formatterMoney.format(
												getTotalPrice()
											)}
										</Button>
									)}
								</Grid>
							</Grid>
						}
						onCustomerCell={(item) => {
							return {
								...item,
								check: (
									<Checkbox
										disabled={
											!!!(
												item.status ===
												EStatusOrder.WAITINGCONFIRM
											)
										}
										checked={Boolean(
											state.listGiftCardSelectedMap.get(
												item.id || ""
											)
										)}
										onChange={() => {
											onClickSelect(item);
										}}
									/>
								),
								id: (
									<Grid
										container
										alignContent="center"
										alignItems="center"
									>
										<Typography>{item.id}</Typography>
										<IconButton
											onClick={(e) => {
												setAnchorElDetail({
													ref: e.currentTarget,
													dataOrderDetail:
														item.orderDetails
															? item.orderDetails
															: null,
													dataCustomer: null,
												});
											}}
										>
											<BsEye />
										</IconButton>
									</Grid>
								),
								status: (
									<Chip
										color={
											item.status ===
											EStatusOrder.COMPLETE
												? "primary"
												: "primary"
										}
										label={
											item.status ===
											EStatusOrder.COMPLETE
												? "Hoàn thành"
												: item.status ===
												  EStatusOrder.IN_DEBT
												? "Còn nợ"
												: "Chờ xác nhận"
										}
									/>
								),
								totalPrice: formatterMoney.format(
									item.totalPrice || 0
								),
								createdAt: (
									<Typography>
										{moment(item.createdAt).format(
											"DD-MM-YYYY HH:mm"
										)}
									</Typography>
								),
								customer: {
									...item.customer,
									email: (
										<Grid
											container
											alignContent="center"
											alignItems="center"
										>
											<Typography>
												{item.customer?.email}
											</Typography>
											<IconButton
												onClick={(e) => {
													setAnchorElDetail({
														ref: e.currentTarget,
														dataOrderDetail: null,
														dataCustomer:
															item.customer,
													});
												}}
											>
												<BsEye />
											</IconButton>
										</Grid>
									),
								},
							};
						}}
					></TableCustom>
				</Grid>
			</Grid>
		</Grid>
	);
}
