import { IncomeSource } from "../model/base-gift-card/model/Income";

export class IncomeHelper {
	static getLabelFromIncome = (label: IncomeSource): string => {
		switch (label) {
			case IncomeSource.ADMIN:
				return "Từ Admin";
			case IncomeSource.BUY_GIFT:
				return "Mua Gift Card";
			case IncomeSource.DEDUCT_BY_ADMIN:
				return "Admin rút tiền";
			default:
				return "Nguồn không xác định";
		}
	};

	static isIncomeUp = (label: IncomeSource): boolean => {
		switch (label) {
			case IncomeSource.ADMIN:
				return true;
			case IncomeSource.BUY_GIFT:
				return false;
			default:
				return false;
		}
	};
}
