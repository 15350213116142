import {
	IGiftCardUserController,
	StatisticsBrand,
	StatisticsIsUsed,
} from "../model/base-gift-card/controller/IGiftCardUserController";
import { StatisticStatusOrder } from "../model/base-gift-card/controller/IOrderController";
import { ListFilter, Paging } from "luong-base-model";
import { GiftCardUser } from "../model/base-gift-card/model/GiftCardUser";
import { BaseController } from "./BaseController";

export class GiftCardUserController
	extends BaseController<GiftCardUser>
	implements IGiftCardUserController
{
	getByOrderIdForCustomer(params: {
		orderId: string;
	}): Promise<GiftCardUser> {
		return this.client
			.get(
				`${this.serviceURL}/${this.basePath}/get-by-order-id-for-customer`,
				{
					params,
				}
			)
			.then((res) => {
				return res.data;
			});
	}
	getByOrderId(params: { orderId: string }): Promise<GiftCardUser> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/get-by-order-id`, {
				params,
			})
			.then((res) => {
				return res.data;
			});
	}
	listForUser(
		params: ListFilter<GiftCardUser>
	): Promise<Paging<GiftCardUser>> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/list_for_user`, {
				params,
			})
			.then((res) => {
				return res.data;
			});
	}
	statisticsOrder(): Promise<StatisticStatusOrder> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/statistics_order`)
			.then((res) => {
				return res.data;
			});
	}
	statisticsOrderPrice(): Promise<StatisticStatusOrder> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/statistics_price_order`)
			.then((res) => {
				return res.data;
			});
	}
	statisticsBrand(): Promise<StatisticsBrand[]> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/statistics_brand`)
			.then((res) => {
				return res.data;
			});
	}
	statisticsIsUsed(): Promise<StatisticsIsUsed> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/statistics_is_used`)
			.then((res) => {
				return res.data;
			});
	}
}
