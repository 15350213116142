/* eslint-disable eqeqeq */
import { Grid, TextField, Button, FormHelperText } from "@material-ui/core";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./App.css";
import BackdropLoading from "./component/genaral-component/BackdropLoading";
import PopupNotification from "./component/genaral-component/PopupNotification";
import { routers, TypeScreen } from "./constants/Route";
import {
	AdminRoute,
	AuthenRoute,
	CustomerRoute,
	PublicRoute,
} from "./constants/RouterAuthen";
import { Dispatch, RootState } from "./rematch/store";

function App() {
	const authen = useSelector((state: RootState) => state.authen);
	const notification = useSelector((state: RootState) => state.notification);
	const dispatch = useDispatch<Dispatch>();
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		if (notification.message) {
			enqueueSnackbar(notification.message, {
				variant: notification.type,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notification]);
	useEffect(() => {
		// todo get list component
		dispatch.authen.getMe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Router>
			{authen.isGet ? (
				<Grid>
					<PopupNotification />
					<BackdropLoading />
					<TransitionGroup>
						<CSSTransition classNames="fade" timeout={300}>
							<Switch>
								{routers.map((route) => {
									if (route.typeAuthen === TypeScreen.admin) {
										return (
											<AdminRoute
												exact
												component={route.component}
												path={route.link}
												authen={authen.info}
											/>
										);
									} else if (
										route.typeAuthen === TypeScreen.customer
									) {
										return (
											<CustomerRoute
												exact
												component={route.component}
												path={route.link}
												authen={authen.info}
											/>
										);
									} else if (
										route.typeAuthen ===
										TypeScreen.adminAndCustomer
									) {
										return (
											<AuthenRoute
												exact
												component={route.component}
												path={route.link}
												authen={authen.info}
											/>
										);
									} else {
										return (
											<PublicRoute
												exact
												component={route.component}
												path={route.link}
												authen={undefined}
											/>
										);
									}
								})}
								<Route path={"*"}>
									<Redirect to="/gift-card" />
								</Route>
							</Switch>
						</CSSTransition>
					</TransitionGroup>
				</Grid>
			) : (
				<Grid></Grid>
			)}
		</Router>
	);
}

export default function IntegrationNotistack() {
	return (
		<SnackbarProvider maxSnack={3}>
			<App />
		</SnackbarProvider>
	);
}
