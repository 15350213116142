import axios, { AxiosError } from "axios";
import config from "../constants/Config";
import { dispatch } from "../rematch/store";
import { BrandController } from "./BrandController";
import { CouponController } from "./CouponController";
import { CustomerController } from "./CustomerController";
import { GiftCardController } from "./GiftCardController";
import { GiftCardUserController } from "./GiftCardUserController";
import { IncomeController } from "./IncomeController";
import { LocalStoryController } from "./LocalStoryController";
import { OrderController } from "./OrderController";
import { UserController } from "./UserController";

export const appClient = axios.create({
	baseURL: config.apiGatewayUrl,
	timeout: 10000,
	headers: {
		common: {
			"Content-Type": "application/json",
		},
	},
});
appClient.interceptors.request.use(
	(res) => {
		dispatch.loading.showLoading();
		return res;
	},
	(err: AxiosError) => {}
);

appClient.interceptors.response.use(
	(res) => {
		dispatch.loading.hiddenLoading();
		return res;
	},
	(err: AxiosError) => {
		if (err.message === "Network Error") {
			dispatch.notification.error("Lỗi kết nối máy chủ");
			// window.location.href = "network-error"
		}
		if (err.response?.status === 401) {
		} else if (err.response?.status === 403) {
		} else {
			dispatch.notification.error(
				err.response?.data?.message || " Có lỗi xảy ra"
			);
		}
		dispatch.loading.hiddenLoading();
		return Promise.reject(err);
	}
);

export const customerController = new CustomerController(
	config.apiGatewayUrl,
	"customer",
	appClient
);
export const giftCardController = new GiftCardController(
	config.apiGatewayUrl,
	"gift_card",
	appClient
);
export const couponController = new CouponController(
	config.apiGatewayUrl,
	"coupon",
	appClient
);
export const brandController = new BrandController(
	config.apiGatewayUrl,
	"brand",
	appClient
);
export const orderController = new OrderController(
	config.apiGatewayUrl,
	"order",
	appClient
);
export const giftCardUserController = new GiftCardUserController(
	config.apiGatewayUrl,
	"gift_card_user",
	appClient
);
export const userController = new UserController(
	config.apiGatewayUrl,
	"user",
	appClient
);

export const incomeController = new IncomeController(
	config.apiGatewayUrl,
	"income",
	appClient
);

export const localStoryController = new LocalStoryController();

//
