import { Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import { RiEdit2Fill } from "react-icons/ri";

const useStyle = makeStyles((theme) => ({
	iconDelete: {
		color: theme.palette.info.main,
		transition: "0.3s",
		"&:hover": {
			background: theme.palette.info.main,
			color: "white",
		},
	},
}));
type Props<T> = {
	action: (item: T) => void;
	item: T;
};
export default function CellComponentEdit<T>(props: Props<T>) {
	const classes = useStyle();
	return (
		<Grid>
			<Tooltip title="Edit">
				<IconButton
					className={classes.iconDelete}
					onClick={(e) => props.action(props.item)}
				>
					<RiEdit2Fill />
				</IconButton>
			</Tooltip>
		</Grid>
	);
}
