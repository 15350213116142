import {
	CircularProgress,
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
import React from "react";
import Chart from "react-google-charts";
import {
	StatisticsBrand,
	StatisticsIsUsed,
} from "../../model/base-gift-card/controller/IGiftCardUserController";
import { StatisticStatusOrder } from "../../model/base-gift-card/controller/IOrderController";

type Props = {
	statisticsIsUsed: StatisticsIsUsed;
	statisticsBrand: StatisticsBrand[];
	statisticStatusOrder: StatisticStatusOrder;
	statisticStatusPriceOrder: StatisticStatusOrder;
};
const useStyle = makeStyles((theme) => ({
	frChart: {
		overflow: "hidden",
		width: "95%",
	},
}));
export default function DashboardPartner(props: Props) {
	const classes = useStyle();
	return (
		<Grid container>
			<Grid container justify="center">
				<Typography variant="h5">Thống kê</Typography>
			</Grid>
			<Grid container justify="space-between">
				<Grid lg={3} md={6} xs={12} className={classes.frChart}>
					<Chart
						width={"95%"}
						height={"300px"}
						chartType="PieChart"
						loader={
							<div>
								<CircularProgress />
							</div>
						}
						data={[
							["Task1", "Hours per Day"],
							["Còn nợ", props.statisticStatusOrder.totalInDebt],
							[
								"Hoàn thành",
								props.statisticStatusOrder.totalComplete,
							],
							[
								"Chờ xác nhận",
								props.statisticStatusOrder.totalWaitingConfirm,
							],
						]}
						options={{
							title: "Tình trạng hóa đơn",
							// Just add this option
							pieHole: 0.4,
						}}
						// rootProps={{ "data-testid": "3" }}
					/>
				</Grid>
				<Grid lg={3} md={6} xs={12} className={classes.frChart}>
					<Chart
						width={"95%"}
						height={"300px"}
						chartType="PieChart"
						loader={
							<div>
								<CircularProgress />
							</div>
						}
						data={[
							["Task1", "Hours per Day"],
							[
								"Còn nợ",
								props.statisticStatusPriceOrder.totalInDebt,
							],
							[
								"Hoàn thành",
								props.statisticStatusPriceOrder.totalComplete,
							],
							[
								"Chờ xác nhận",
								props.statisticStatusPriceOrder
									.totalWaitingConfirm,
							],
						]}
						options={{
							title: "Tiền trên loại hóa đơn",
							// Just add this option
							pieHole: 0.4,
						}}
						// rootProps={{ "data-testid": "3" }}
					/>
				</Grid>
				<Grid lg={3} md={6} xs={12} className={classes.frChart}>
					<Chart
						width={"95%"}
						height={"300px"}
						chartType="PieChart"
						loader={
							<div>
								<CircularProgress />
							</div>
						}
						data={[
							["Task", "Hours per Day"],
							["Đã sử dụng", props.statisticsIsUsed.notUsed],
							["Chưa sử dụng", props.statisticsIsUsed.used],
						]}
						options={{
							title: "Tình trạng sử dụng",
							// Just add this option
							pieHole: 0.4,
						}}
						rootProps={{ "data-testid": "3" }}
					/>
				</Grid>
				<Grid lg={3} md={6} xs={12} className={classes.frChart}>
					<Chart
						width={"95%"}
						height={"300px"}
						chartType="PieChart"
						loader={<div>Loading Chart</div>}
						data={[
							["Task", "Hours per Day"],
							...props.statisticsBrand.map((item) => [
								item.name,
								item.value,
							]),
						]}
						options={{
							title: "Loại thẻ",
							// Just add this option
							pieHole: 0.4,
						}}
						rootProps={{ "data-testid": "3" }}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
