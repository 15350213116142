import PhoneNumber from "awesome-phonenumber";

export const formatterMoney = new Intl.NumberFormat("uk-EN", {
	style: "currency",
	currency: "GBP",
	minimumFractionDigits: 0,
});

export const formatterPhoneVN =  (phone: string): string=>{
	var pn = new PhoneNumber(
		phone || "",
		"VN"
	);
	return pn.getNumber("international")
}
