/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { formatterMoney } from "../../helpers/StringHelper";
import { Brand } from "../../model/base-gift-card/model/Brand";
import { Customer } from "../../model/base-gift-card/model/Customer";
import { GiftCardUser } from "../../model/base-gift-card/model/GiftCardUser";
import { useGlobalStyles } from "../../style/GlobalStyle";
import BaseDialog from "../genaral-component/BaseDialog";
const CKEditor = require("ckeditor4-react");

type Props = {
	isDisplay: boolean;
	item: Customer;
	onEdit: (item: Customer) => void;
	onCancel: () => void;
};

export default function PopupDescGiftCardUser(props: Props) {
	const formik = useFormik<GiftCardUser>({
		initialValues: {
			desc: "",
			name: "",
		} as Brand,
		onSubmit: () => {
			props.onEdit(formik.values);
		},
	});
	useEffect(() => {
		if (props.isDisplay) {
			formik.setValues(
				_.cloneDeep({
					...props.item,
				})
			);
		}
	}, [props]);

	const onChangeRemain = (value: number) => {
		if (
			formik.values &&
			value <= (formik.values.giftCard?.denomination || 0)
		) {
			formik.setValues({
				...formik.values,
				remain: value,
			});
		} else {
			formik.setErrors({
				...formik.errors,
				remain: `Không được lớn hơn ${formatterMoney.format(
					formik.values.giftCard?.denomination || 0
				)}`,
			});
		}
	};
	const globalStyles = useGlobalStyles();
	return (
		<Grid>
			<BaseDialog
				isDisplay={props.isDisplay}
				onCancel={props.onCancel}
				onClickConfirm={() => {
					formik.handleSubmit();
				}}
				title="Thông tin"
			>
				<Grid container direction="column" justify="space-around">
					<Grid>
						<TextField
							value={formik.values.remain || 0}
							helperText={formik.errors.remain}
							name="remain"
							onChange={(e) =>
								onChangeRemain(Number(e.target.value) || 0)
							}
							onBlur={formik.handleBlur}
							fullWidth
							type="number"
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Giá trị còn lại"
						></TextField>
					</Grid>
					<Grid>
						<CKEditor
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							data={formik.values.desc}
							onChange={(e: any) => {
								formik.setValues({
									...formik.values,
									desc: e.editor.getData(),
								});
							}}
						/>
					</Grid>
				</Grid>
			</BaseDialog>
		</Grid>
	);
}
