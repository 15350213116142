import {
	Grid,
	Hidden,
	IconButton,
	makeStyles,
	SwipeableDrawer,
	Typography,
	Zoom,
} from "@material-ui/core";
import React from "react";
import Menu from "../component/Menu";
import { useGlobalStyles } from "../style/GlobalStyle";
import clsx from "clsx";
import { useState } from "react";
import { GiftCard } from "../model/base-gift-card/model/GiftCard";
import PopupGiftCardDetail from "../component/gift-card/PopupGiftCardDetail";
import { IoIosOptions } from "react-icons/io";
import ListGrid from "../component/genaral-component/ListGrid";
import { Pagination } from "@material-ui/lab";
import { useCrudHook } from "../helpers/CrudHook";
import {
	brandController,
	giftCardController,
	orderController,
} from "../controller";
import Footer from "../component/Footer";
import GiftCardItem from "../component/gift-card/GiftCardItem";
import FilterGiftCard from "../component/partner/FilterGiftCard";
import { useEffect } from "react";
import { Brand } from "../model/base-gift-card/model/Brand";
import theme from "../theme/MuiTheme";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../rematch/store";
import { ETypeCustomer } from "../model/base-gift-card/model/Customer";
import { ETypePayment } from "../model/base-gift-card/controller/IOrderController";
const useStyle = makeStyles((theme) => ({
	fr: {
		padding: theme.spacing(3),
		border: `1px solid ${theme.palette.grey[200]}`,
	},
}));
export default function GiftCardContainer() {
	const classes = useStyle();
	const dispatch = useDispatch<Dispatch>();
	const authen = useSelector((state: RootState) => state.authen);
	const globalStyle = useGlobalStyles();
	const [brand, setBrand] = useState<Brand[]>();
	const [state, setState] = useState<{
		isOpenNavBar: boolean;
		isDisplayPopupDetail: boolean;
		itemPopupDetail: GiftCard;
	}>({
		isDisplayPopupDetail: false,
		itemPopupDetail: {},
		isOpenNavBar: false,
	});
	const crud = useCrudHook<GiftCard>({
		controller: giftCardController,
		listController: giftCardController.listForCustomer,
		initQuery: { pageSize: 20 },
	});
	const onOrder = (item: GiftCard & { typePayment: ETypePayment }) => {
		if (item.id) {
			orderController
				.createOrder({
					productId: item.id,
					typePayment: item.typePayment,
				})
				.then((res) => {
					crud.onRefreshList();
					handleCloseConfirmBuy();
				})
				.catch((err) => {
					dispatch.notificationPopup.error({
						message: err.response.data.message,
						title: "Cảnh báo",
					});
					handleCloseConfirmBuy();
				});
		}
	};
	const toggleDrawer = (open: boolean) => {
		setState({
			...state,
			isOpenNavBar: open,
		});
	};
	const handleCloseConfirmBuy = () => {
		setState({
			...state,
			isDisplayPopupDetail: false,
			itemPopupDetail: {},
			isOpenNavBar: false,
		});
	};
	const confirmBuy = (item: GiftCard) => {
		setState({
			...state,
			isDisplayPopupDetail: true,
			itemPopupDetail: item,
		});
	};
	useEffect(() => {
		Promise.all([brandController.find({})]).then((_res) => {
			setBrand(_res[0]);
		});
	}, []);
	return (
		<Grid container>
			<PopupGiftCardDetail
				typeCustomer={authen.info?.typeCustomer || ETypeCustomer.NEW}
				isDisplay={state.isDisplayPopupDetail}
				onCancel={() => {
					setState({
						...state,
						isDisplayPopupDetail: false,
						itemPopupDetail: {},
						isOpenNavBar: false,
					});
				}}
				item={state.itemPopupDetail}
				onOrder={onOrder}
			/>
			<SwipeableDrawer
				anchor={"right"}
				open={state.isOpenNavBar}
				onClose={() => {
					toggleDrawer(false);
				}}
				onOpen={() => {
					toggleDrawer(true);
				}}
			>
				<Grid
					container
					className={clsx(globalStyle.pp2)}
					justify="flex-start"
					style={{}}
				>
					<FilterGiftCard
						bands={brand || []}
						onQuery={(query) => {
							setState({
								...state,
								isOpenNavBar: false,
							});
							crud.setQuery(query);
						}}
						query={crud.query}
					/>
				</Grid>
			</SwipeableDrawer>
			<Grid container justify="center">
				<Grid
					lg={10}
					style={{
						marginTop: 90,
					}}
				>
					<Grid
						className={clsx(classes.fr, globalStyle.mt3)}
						container
					>
						<Grid
							container
							alignItems="center"
							alignContent="center"
							className={clsx(globalStyle.pp4)}
						>
							<Hidden mdUp>
								<Grid
									className={clsx(globalStyle.pr2)}
									style={{
										position: "fixed",
										top: theme.spacing(1),
										right: theme.spacing(1),
										zIndex: 10000,
									}}
								>
									<IconButton
										onClick={() =>
											toggleDrawer(!state.isOpenNavBar)
										}
									>
										<IoIosOptions />
									</IconButton>
								</Grid>
							</Hidden>
							<Grid>
								<Typography variant="h5">Sản phẩm</Typography>
							</Grid>
						</Grid>
						<Grid container className={clsx(globalStyle.pp1)}>
							<Grid md={3}>
								<Hidden smDown>
									<FilterGiftCard
										bands={brand || []}
										onQuery={crud.setQuery}
										query={crud.query}
									/>
								</Hidden>
							</Grid>
							<Grid md={9} container>
								<ListGrid minWidthItem={"300px"} gridGap={10}>
									{crud.pagingList.rows?.map(
										(item, index) => (
											<Zoom
												in={true}
												timeout={index * 100}
											>
												<Grid
													container
													justify="center"
												>
													<GiftCardItem
														item={item}
														onOrder={confirmBuy}
													/>
												</Grid>
											</Zoom>
										)
									)}
								</ListGrid>
								<Grid
									container
									justify="center"
									className={clsx(globalStyle.pp4)}
								>
									<Pagination
										count={crud.pagingList.totalPages}
										onChange={(e, page) => {
											crud.setQuery({
												...crud.query,
												page: page,
											});
										}}
										page={crud.pagingList.page}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid container>
				<Footer></Footer>
			</Grid>
		</Grid>
	);
}
