import { BaseModel } from 'luong-base-model';
import { Customer } from './Customer';
import { OrderDetail } from './OrderDetail';

export class Order extends BaseModel {
  customerId?: string;

  totalPrice?: number;

  couponId?: string;

  desc?: string;

  status?: EStatusOrder;

  orderDetails?: OrderDetail[];

  customer?: Customer;
}

export enum EStatusOrder {
  COMPLETE = 'complete',
  WAITINGCONFIRM = 'WAITINGCONFIRM',
  FAIL = 'FAIL',
  IN_DEBT = 'IN_DEBT',
}
