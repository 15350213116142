import {
	ETypePayment,
	FilterStatistic,
	IntervalOrder,
	IOrderController,
	PropsCreateOrder,
	StatisticAmount,
	StatisticRevenue,
	StatisticStatusOrder,
} from "../model/base-gift-card/controller/IOrderController";
import { Order } from "../model/base-gift-card/model/Order";
import { BaseController } from "./BaseController";

export class OrderController
	extends BaseController<Order>
	implements IOrderController
{
	statisticStatusOrderPrice(
		params: FilterStatistic
	): Promise<StatisticStatusOrder> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/statistic-status-price`, {
				params,
			})
			.then((res) => {
				return res.data;
			});
	}
	statisticStatusOrderAmount(
		params: FilterStatistic
	): Promise<StatisticStatusOrder> {
		return this.client
			.get(
				`${this.serviceURL}/${this.basePath}/statistic-status-amount`,
				{
					params,
				}
			)
			.then((res) => {
				return res.data;
			});
	}
	confirmComplete(params: {
		orderId: string[];
		typePayment: ETypePayment;
	}): Promise<Order[]> {
		return this.client
			.post(
				`${this.serviceURL}/${this.basePath}/confirm-complete`,
				params
			)
			.then((res) => {
				return res.data;
			});
	}
	confirmPayment(params: {
		orderId: string[];
		typePayment: ETypePayment;
	}): Promise<Order[]> {
		return this.client
			.post(`${this.serviceURL}/${this.basePath}/confirm-payment`, params)
			.then((res) => {
				return res.data;
			});
	}
	intervalAmount(params: FilterStatistic): Promise<IntervalOrder[]> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/interval-amount`, {
				params,
			})
			.then((res) => {
				return res.data;
			});
	}
	intervalRevenue(params: FilterStatistic): Promise<IntervalOrder[]> {
		return this.client
			.get(`${this.serviceURL}/${this.basePath}/interval-revenue`, {
				params,
			})
			.then((res) => {
				return res.data;
			});
	}

	createOrder(param: PropsCreateOrder): Promise<Order> {
		return this.client
			.post(`${this.serviceURL}/${this.basePath}/create-order`, param)
			.then((res) => {
				return res.data;
			});
	}
}
