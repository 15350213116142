/* eslint-disable jsx-a11y/alt-text */
import { Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { BiSort } from "react-icons/bi";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";
import { FilterHelper } from "../../helpers/FilterHelper";
import { ListFilter } from "luong-base-model";
import { Brand } from "../../model/base-gift-card/model/Brand";
import { GiftCard } from "../../model/base-gift-card/model/GiftCard";
import { useGlobalStyles } from "../../style/GlobalStyle";
import theme from "../../theme/MuiTheme";
const useStyle = makeStyles((theme) => ({
	root: {
		borderRight: `1px solid ${theme.palette.grey[200]}`,
		width: 300,
	},
	frLogo: {
		borderRadius: theme.spacing(1),
		overflow: "hidden",
		height: "2rem",
		width: "2rem",
	},
	logoGiftImg: {
		height: "100%",
	},
	filterItem: {
		cursor: "pointer",
	},
}));

type Props = {
	query: ListFilter<GiftCard>;
	onQuery: (query: ListFilter<GiftCard>) => void;
	bands: Brand[];
};
export default function FilterGiftCard(props: Props) {
	const classes = useStyle();
	const globalStyle = useGlobalStyles();
	const filterBrand = (brand: Brand) => {
		console.log(brand);

		if (brand.id === "all") {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					brandId: undefined,
				},
			});
		} else {
			props.onQuery({
				...props.query,
				filter: {
					...props.query.filter,
					brandId: brand.id,
				},
			});
		}
	};
	const checkFilterBrand = (id: string): boolean => {

		if (
			props.query &&
			props.query.filter &&
			props.query.filter.brandId === id
		) {
			return true;
		} else if (id === "all" && !props.query?.filter?.brandId) return true;
		return false;
	};
	const getIconSort = (
		sort: string | string[],
		valueCheck: string
	): React.ReactElement => {
		const newSort = typeof sort === "string" ? sort : sort[0];
		if (!newSort || _.snakeCase(valueCheck) !== _.snakeCase(newSort)) {
			return <BiSort />;
		}
		if (newSort.startsWith("-")) {
			return <FaSortAmountDownAlt color={theme.palette.secondary.main} />;
		} else {
			return <FaSortAmountUpAlt color={theme.palette.secondary.main} />;
		}
	};
	const sortFiler = (key: string) => {
		props.onQuery(FilterHelper.addSort(props.query, key));
	};

	// const getValueSortByKey = (key: string) => {
	// 	let value: any = FilterHelper.getFilterByKey(
	// 		props.query?.filter || [],
	// 		key
	// 	);
	// 	if (value) value = value.values;
	// 	if (value) value = value[0];
	// 	if (typeof value === "boolean") return value;
	// 	return value || "all";
	// };

	return (
		<Grid className={clsx(globalStyle.pp1, globalStyle.pt0, classes.root)}>
			<Grid>
				<Grid className={clsx(globalStyle.pp1)}>
					<Typography variant="h6">LỌC THEO HÃNG</Typography>
				</Grid>
				<Grid
					className={clsx(globalStyle.pp1)}
					container
					alignContent="center"
					alignItems="center"
				>
					{[{ id: "all", name: "Tất cả" }, ...props.bands].map(
						(item) => (
							<Grid
								container
								className={clsx(
									// globalStyle.pp1,
									classes.filterItem
								)}
								alignContent="center"
								alignItems="center"
								onClick={() => filterBrand(item)}
							>
								<Grid className={classes.frLogo}>
									<img
										src="/image/gift.png"
										className={classes.logoGiftImg}
									></img>
								</Grid>
								<Grid className={clsx(globalStyle.pp1)}>
									<Typography
										style={{
											color: checkFilterBrand(
												item.id || "all"
											)
												? theme.palette.secondary.main
												: "",
										}}
									>
										{item.name || ""}
									</Typography>
								</Grid>
							</Grid>
						)
					)}
				</Grid>
			</Grid>
			<Grid>
				<Grid className={clsx(globalStyle.pp1)}>
					<Typography variant="h6">SẮP XẾP</Typography>
				</Grid>
				<Grid
					className={clsx(globalStyle.pp1)}
					container
					alignContent="center"
					alignItems="center"
				>
					<Grid container alignContent="center" alignItems="center">
						<Grid>
							{getIconSort(props.query.sort || "", "createdAt")}
						</Grid>
						<Grid className={clsx(globalStyle.pp1)}>
							<Typography
								className={clsx(classes.filterItem)}
								onClick={() => sortFiler("createdAt")}
							>
								Ngày phát hành
							</Typography>
						</Grid>
					</Grid>
					<Grid container alignContent="center" alignItems="center">
						<Grid>
							{getIconSort(
								props.query.sort || "",
								"denomination"
							)}
						</Grid>
						<Grid className={clsx(globalStyle.pp1)}>
							<Typography
								className={clsx(classes.filterItem)}
								onClick={() => sortFiler("denomination")}
							>
								Trị giá
							</Typography>
						</Grid>
					</Grid>
					<Grid container alignContent="center" alignItems="center">
						<Grid>
							{getIconSort(props.query.sort || "", "priceSale")}
						</Grid>
						{/* createdAtGiftCardUser */}
						<Grid className={clsx(globalStyle.pp1)}>
							<Typography
								className={clsx(classes.filterItem)}
								onClick={() => sortFiler("priceSale")}
							>
								Giá
							</Typography>
						</Grid>
					</Grid>
					<Grid container alignContent="center" alignItems="center">
						<Grid>
							{getIconSort(
								props.query.sort || "",
								"percentDiscount"
							)}
						</Grid>
						{/* createdAtGiftCardUser */}
						<Grid className={clsx(globalStyle.pp1)}>
							<Typography
								className={clsx(classes.filterItem)}
								onClick={() => sortFiler("percentDiscount")}
							>
								Phần trăm giảm giá
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
