import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { formatterPhoneVN } from "../../helpers/StringHelper";
import { Customer } from "../../model/base-gift-card/model/Customer";

const useStyle = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		background: "white",
		// borderRadius: theme.spacing(1),
		minWidth: 300,
	},
	itemInfo: {
		borderBottom: `1px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(1),
		margin: theme.spacing(1),
	},
}));
type Props = {
	item: Customer | null | undefined;
};
export default function CustomerDetailOrderPopup(props : Props) {
	const classes = useStyle();
	return (
		<Grid className={classes.root} container justify="center">
			<Grid xs={12}>
				<Typography align="center" variant="h5">
					Chi tiết
				</Typography>
			</Grid>
			<Grid xs={5} className={classes.itemInfo}>
				<Typography color="primary">Tên khách hàng</Typography>
				<Typography>{props.item?.fullName}</Typography>
			</Grid>
			<Grid xs={5} className={classes.itemInfo}>
				<Typography color="primary">Số điện thoại</Typography>
				<Typography> {formatterPhoneVN(props.item?.phoneNumber || "")} </Typography>
			</Grid>
			<Grid xs={5} className={classes.itemInfo}>
				<Typography color="primary">Email</Typography>
				<Typography> {props.item?.email} </Typography>
			</Grid>
			<Grid xs={5} className={classes.itemInfo}>
				<Typography color="primary">Mô tả</Typography>
				<Typography> {props.item?.desc} </Typography>
			</Grid>
		</Grid>
	);
}
