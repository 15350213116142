/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
	Button,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Slide,
	Typography,
	Zoom,
} from "@material-ui/core";
import _ from "lodash";
import IncomeItem from "../component/customer/IncomeItem";
import { useGlobalStyles } from "../style/GlobalStyle";
import { FaBitcoin } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";
import PopupAddIncomeForUser from "../component/income/PopupAddIncomeForUser";
import { handleWithPopupHook } from "../helpers/HandleWithPopupHook";
import { Customer } from "../model/base-gift-card/model/Customer";
import { customerController, incomeController } from "../controller";
import { Income, IncomeSource } from "../model/base-gift-card/model/Income";
import { dispatch } from "../rematch/store";
import { useCrudHook } from "../helpers/CrudHook";
import PopUpConfirm from "../component/genaral-component/PopupConfirm";
import { Finance } from "../model/base-gift-card/model/Finance";
import { formatterMoney } from "../helpers/StringHelper";
import PopupIncomeDetail from "../component/income/PopupIncomeDetail";

type Props = {};
const useStyle = makeStyles((theme) => ({
	root: {
		background: "white",
		padding: theme.spacing(3),
	},
	iconStatistic: {
		fontSize: "2rem",
		color: "orange",
	},
	frInfoUser: {
		padding: theme.spacing(6),
	},
	amountUp: {
		color: theme.palette.success.main,
		fontSize: "1.6rem",
	},
	amountDown: {
		color: theme.palette.error.main,
		fontSize: "1.6rem",
	},
}));
function IncomeCustomer(props: Props) {
	const { id } = useParams<{ id: string }>();
	const classes = useStyle();
	const [customer, setCustomer] = useState<Customer>({});
	const [state, setState] = useState<{
		finance: Finance;
	}>({
		finance: {},
	});
	const handleWithPopupIncomeDetail = handleWithPopupHook<Income>({});
	const crudIncome = useCrudHook<Income>({
		controller: incomeController,
		initQuery: {
			pageSize: 10000,
			filter: {
				customerId: id,
			},
		},
		saveController: incomeController.addIncomeByAdmin,

		onAfterSave: (item) => {
			if (item.incomeSource == IncomeSource.ADMIN) {
				dispatch.notification.success(
					`Bạn đã thêm ${item.amount}đ vào khách hàng ${item?.customer?.fullName}`
				);
			} else {
				dispatch.notification.error(
					`Bạn đã trừ ${item.amount}đ vào khách hàng ${item?.customer?.fullName}`
				);
			}
		},
		onAfterQuery: (item) => {
			incomeController.getFinanceByCustomerId(id).then((res) => {
				setState({
					...state,
					finance: res,
				});
			});
		},
	});
	const globalStyle = useGlobalStyles();

	useEffect(() => {
		customerController.getById(id).then((res) => {
			setCustomer(res);
		});
	}, []);

	const itemStatistics = (params: {
		from: string;
		amount: number;
		type: "up" | "down";
	}): React.ReactElement => (
		<Grid>
			<Grid container alignItems="center">
				<Grid className={globalStyle.pr3}>
					<FaBitcoin className={classes.iconStatistic} />
				</Grid>
				<Grid>
					<Typography variant="caption">{params.from}</Typography>
					<Typography
						variant="body2"
						className={
							params.type === "up"
								? classes.amountUp
								: classes.amountDown
						}
					>
						{formatterMoney.format(params.amount || 0)}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
	return (
		<Grid className={globalStyle.pp1}>
			<PopupIncomeDetail
				isDisplay={handleWithPopupIncomeDetail.isDisplayPopup}
				onCancel={handleWithPopupIncomeDetail.handleClosePopup}
				item={handleWithPopupIncomeDetail.itemSelected}
				isAdmin={true}
			/>
			<PopupAddIncomeForUser
				isDisplay={crudIncome.isShowPopup}
				onCancel={crudIncome.onCancelPopup}
				item={crudIncome.itemSelected}
				onEdit={crudIncome.onSave}
			/>
			<PopUpConfirm
				isDisplay={crudIncome.isShowConfirm}
				onCancel={crudIncome.onCancelConfirm}
				onConfirm={() => crudIncome.onDelete(crudIncome.itemSelected)}
			/>
			<Grid container className={classes.root}>
				<Grid container className={classes.frInfoUser}>
					<Grid container justify="center">
						<Typography align="center" variant="h3">
							{customer.fullName}
						</Typography>
						<IconButton
							color={"primary"}
							onClick={(e) =>
								crudIncome.onShowPopup({
									customer: { ...customer },
									customerId: id,
								})
							}
						>
							<IoMdAddCircleOutline />
						</IconButton>
					</Grid>
					<Grid container justify="center">
						<Typography
							align="center"
							variant="h3"
							color="secondary"
						>
							{formatterMoney.format(
								state.finance.remainAmount || 0
							)}
						</Typography>
					</Grid>
					<Grid container justify="space-around">
						{itemStatistics({
							from: "Tổng tiền vào",
							amount: state.finance.adminAddAmount || 0,
							type: "up",
						})}
						{itemStatistics({
							from: "Tổng tiền ra",
							amount: state.finance.buyedAmount || 0,
							type: "down",
						})}
					</Grid>
				</Grid>
				<Grid container>
					{crudIncome.pagingList.rows?.map((item, index) => {
						return (
							<Slide
								direction="left"
								in={true}
								key={index}
								timeout={index * 150}
							>
								<Grid container>
									<IncomeItem
										item={item}
										onDelete={crudIncome.onConfirm}
										onSeeDetail={
											handleWithPopupIncomeDetail.handleShowPopup
										}
									></IncomeItem>
								</Grid>
							</Slide>
						);
					})}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default React.memo(IncomeCustomer);
