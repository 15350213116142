import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import CellComponentDelete from "../component/genaral-component/CellComponentDelete";
import CellComponentEdit from "../component/genaral-component/CellComponentEdit";
import PopUpConfirm from "../component/genaral-component/PopupConfirm";
import TableCustom, { ColumnTable } from "../component/genaral-component/Table";
import PopupGiftCard from "../component/gift-card/PopupGiftCard";
import { brandController, giftCardController } from "../controller";
import { useCrudHook } from "../helpers/CrudHook";
import { FilterHelper } from "../helpers/FilterHelper";
import { formatterMoney } from "../helpers/StringHelper";
import {
	FilterUsed,
	ListFilterGiftCardAdmin,
} from "../model/base-gift-card/controller/IGiftCardController";
import { Brand } from "../model/base-gift-card/model/Brand";
import { GiftCard } from "../model/base-gift-card/model/GiftCard";
import theme from "../theme/MuiTheme";

export default function GiftCardManager() {
	const [column, setColumn] = useState<ColumnTable<GiftCard>[]>([
		{
			id: "brand.name" as any,
			label: "Tên hãng",
		},
		{
			id: "codeCard",
			label: "Mã thẻ",
			acceptSearch: true,
			isSort: true,
		},
		{
			id: "codePin",
			label: "Mã pin",
			acceptSearch: true,
			isSort: true,
		},
		{
			id: "percentDiscount",
			label: "% giảm",
			acceptSearch: true,
			isSort: true,
		},
		{
			id: "priceSale",
			label: "Giá bán",
			acceptSearch: true,
			isSort: true,
		},
		{
			id: "denomination",
			label: "Mệnh giá",
			acceptSearch: true,
			isSort: true,
		},
		{
			id: "usedAt",
			label: "Trạng thái",
			acceptSearch: true,
			isSort: true,
		},
		{
			id: "desc",
			label: "Mô tả",
		},
		{
			id: "action" as any,
			label: "Hành động",
			acceptSearch: false,
			isSort: false,
		},
	]);
	const crud = useCrudHook<GiftCard, ListFilterGiftCardAdmin>({
		controller: giftCardController,
		initQuery: {
			pageSize: 5,
			filterUsed: FilterUsed.notUsed,
		},
	});
	const [brands, setBrands] = useState<Brand[]>();
	useEffect(() => {
		brandController.find({}).then((res) => setBrands(res));
	}, []);
	return (
		<Grid
			style={{
				padding: theme.spacing(1),
			}}
		>
			<PopupGiftCard
				isDisplay={crud.isShowPopup}
				item={crud.itemSelected}
				onCancel={crud.onCancelPopup}
				onEdit={crud.onSave}
			/>
			<PopUpConfirm
				isDisplay={crud.isShowConfirm}
				onCancel={crud.onCancelConfirm}
				onConfirm={() => crud.onDelete(crud.itemSelected)}
			/>
			<Grid
				style={{
					background: theme.palette.background.paper,
					padding: theme.spacing(6),
				}}
				container
			>
				<Grid item xs={12} container justify="space-between">
					<Typography variant="h4">Gift Card</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={(e) => crud.onShowPopup({})}
					>
						Thêm mới
					</Button>
				</Grid>
				<Grid item xs={12}>
					<TableCustom<GiftCard>
						column={column}
						data={crud.pagingList}
						onChangeColumn={(newColumn) => setColumn(newColumn)}
						onQuery={crud.setQuery}
						otherFilter={
							<Grid container justify="space-between">
								<FormControl variant="outlined">
									<InputLabel>{/* Lọc */}</InputLabel>
									<Select
										value={
											crud.query.filterUsed ||
											FilterUsed.notUsed
										}
										onChange={(e) => {
											crud.setQuery({
												...crud.query,
												filterUsed: e.target
													.value as FilterUsed,
											});
										}}
									>
										<MenuItem value={FilterUsed.all}>
											Tất cả
										</MenuItem>
										<MenuItem value={FilterUsed.notUsed}>
											Chưa mua
										</MenuItem>
										<MenuItem value={FilterUsed.used}>
											Đã mua
										</MenuItem>
									</Select>
								</FormControl>
								<Autocomplete
									options={brands || []}
									getOptionLabel={(option) =>
										option.name || ""
									}
									style={{ width: 300 }}
									onChange={(e, value) => {
										if (value) {
											crud.setQuery({
												...crud.query,
												filter: {
													...crud.query.filter,
													brandId: [value.id || ""],
												},
											});
										} else {
											crud.setQuery({
												...crud.query,
												filter: {
													...crud.query.filter,
													brandId: [],
												},
											});
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Hãng"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
						}
						isShowHighlightText={true}
						query={crud.query}
						onCustomerCell={(item) => {
							return {
								...item,
								denomination: formatterMoney.format(
									item.denomination || 0
								),
								priceSale: formatterMoney.format(
									item.priceSale || 0
								),
								percentDiscount: `${item.percentDiscount}%`,
								usedAt: item.usedAt ? "Đã mua" : "Chưa mua",
								action: (
									<Grid container>
										{
											<CellComponentDelete
												action={crud.onConfirm}
												item={item}
											/>
										}
										{
											<CellComponentEdit
												action={crud.onShowPopup}
												item={item}
											/>
										}
									</Grid>
								),
							};
						}}
					></TableCustom>
				</Grid>
			</Grid>
		</Grid>
	);
}
