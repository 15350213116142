import _ from "lodash";
import { Filter, ListFilter } from "luong-base-model";

export class FilterHelper {
	// public static addFilter<T = any>(
	// 	params: ListFilter<T>,
	// 	filter: Filter<T>
	// ): ListFilter<T> {
	// 	const newFilter: Filter<T>[] = [...(params.filter || [])];
	// 	const index = newFilter.findIndex((ref) => ref.field === filter.field);
	// 	if (index >= 0) {
	// 		newFilter[index] = filter;
	// 	} else {
	// 		newFilter.push(filter);
	// 	}
	// 	params.filter = newFilter;
	// 	return params;
	// }
	// public static getFilterByKey<T = any>(
	// 	params: Filter<T>[],
	// 	key: string
	// ): Filter<T> {
	// 	const newFilter: Filter<T>[] = [...(params || [])];
	// 	return (
	// 		newFilter.filter((item) => item.field === key)[0] ||
	// 		({
    //     field: key,
    //     value: [],
    //   } as unknown as Filter<T>)
	// 	);
	// }

	public static addSort<T = any>(
		params: ListFilter<T>,
		column: keyof T | string
	): ListFilter<T> {
		column = column.toString() as string;
		let sort: string[] = (params.sort as []) || [];
		const getColumnIndex: number = sort.findIndex(
			(item) => _.snakeCase(item) === _.snakeCase(column as string)
		);
		if (getColumnIndex < 0) sort = [column];
		// sort =  sort.concat(column)
		else {
			const getColumn: string = sort[getColumnIndex];
			if (getColumn.startsWith("-")) {
				sort.splice(getColumnIndex, 1);
			} else {
				sort[getColumnIndex] = `-${column}`;
			}
		}
		return {
			...params,
			sort,
		};
	}
}
