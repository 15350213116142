/* eslint-disable react-hooks/exhaustive-deps */
import {
	FormControl,
	FormHelperText,
	Grid,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { brandController } from "../../controller";
import { Brand } from "../../model/base-gift-card/model/Brand";
import { GiftCard } from "../../model/base-gift-card/model/GiftCard";
import { useGlobalStyles } from "../../style/GlobalStyle";
import BaseDialog from "../genaral-component/BaseDialog";

type Props = {
	isDisplay: boolean;
	item: GiftCard;
	onEdit: (item: GiftCard) => void;
	onCancel: () => void;
};
const validate = Yup.object({
	desc: Yup.string().max(300, "Chữ không được quá 300 kí tự").nullable(),
	codeCard: Yup.string()
		.max(100, "Chữ không được quá 100 kí tự")
		.required("Không được để trống !!")
		.trim().nullable(),
	codePin: Yup.string()
		.max(100, "Chữ không được quá 100 kí tự")
		.required("Không được để trống !!")
		.trim().nullable(),
	denomination: Yup.number()
		.max(10000000, "Mệnh giá không được lớn hơn 10,000,000")
		.required("Không được để trống mệnh giá").nullable(),
	brandId: Yup.string().required("Vui lòng chọn hãng").nullable(),
	percentDiscount: Yup.number().max(100, "Giá trị không được lớn hơn 100 ").nullable(),
});

export default function PopupGiftCard(props: Props) {
	const [brand, setBrand] = useState<{
		items: Brand[];
		itemsMap: Map<string, Brand>;
	}>();
	const formik = useFormik<GiftCard>({
		initialValues: {} as GiftCard,
		validationSchema: validate,
		onSubmit: () => {
			props.onEdit(formik.values);
		},
	});
	const onSubmit = () => {
		formik.handleSubmit();
		formik.setTouched(_.mapValues(new GiftCard(), () => true));
	};

	const onChangePrice = (denomination: number) => {
		const values = formik.values;
		formik.setValues({
			...values,
			denomination: denomination || 0,
			priceSale:
				(denomination || 0) *
				((100 - (values.percentDiscount || 0)) / 100),
		});
	};
	const onChangePriceSale = (priceSale: number) => {
		const values = formik.values;
		formik.setValues({
			...values,
			priceSale: priceSale,
			percentDiscount:
				100 - (priceSale / (values.denomination || 0)) * 100,
		});
	};
	const onChangePercentSale = (percentDiscount: number) => {
		formik.setValues({
			...formik.values,
			percentDiscount: percentDiscount,
			priceSale:
				(formik.values.denomination || 0) *
				((100 - percentDiscount) / 100),
		});
	};

	useEffect(() => {
		if (props.isDisplay) {
			formik.setValues(
				_.cloneDeep({
					...props.item,
					price: props.item.denomination || 0,
					priceDiscount: props.item.priceSale || 0,
					percentDiscount: props.item.percentDiscount || 0,
				})
			);
			brandController.find({}).then((res) => {
				const brandMap = new Map<string, Brand>();
				res.map((item) => brandMap.set(item.id || "", item));
				setBrand({
					items: res,
					itemsMap: brandMap,
				});
			});
			formik.setTouched(_.mapValues(new GiftCard(), () => false));
		}
	}, [props]);
	const globalStyles = useGlobalStyles();
	return (
		<Grid>
			<BaseDialog
				isDisplay={props.isDisplay}
				onCancel={props.onCancel}
				onClickConfirm={() => {
					onSubmit();
				}}
				title="Gift card"
			>
				<Grid container direction="column" justify="space-around">
					<Grid>
						<TextField
							value={formik.values.codeCard}
							helperText={
								formik.touched.codeCard &&
								formik.errors.codeCard
							}
							error={
								!!formik.errors.codeCard &&
								formik.touched.codeCard
							}
							name="codeCard"
							onChange={(e)=>{
								formik.setValues({
									...formik.values,
									codeCard : (e.target.value).toString().replace( /\s/g, '')
								})
							}}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Mã thẻ"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.codePin}
							helperText={
								formik.touched.codePin && formik.errors.codePin
							}
							error={
								!!formik.errors.codePin &&
								formik.touched.codePin
							}
							name="codePin"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Mã pin"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.denomination}
							helperText={
								formik.touched.denomination &&
								formik.errors.denomination
							}
							error={
								!!formik.errors.denomination &&
								formik.touched.denomination
							}
							name="price"
							type="number"
							onChange={(e) =>
								onChangePrice(parseInt(e.target.value))
							}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Giá gốc"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.percentDiscount}
							helperText={
								formik.touched.percentDiscount &&
								formik.errors.percentDiscount
							}
							error={
								!!formik.errors.percentDiscount &&
								formik.touched.percentDiscount
							}
							name="percentDiscount"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) =>
								onChangePercentSale(parseInt(e.target.value))
							}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Phần trăm giảm giá"
						></TextField>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.priceSale}
							helperText={
								formik.touched.priceSale &&
								formik.errors.priceSale
							}
							error={
								!!formik.errors.priceSale &&
								formik.touched.priceSale
							}
							name="priceDiscount"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) =>
								onChangePriceSale(parseInt(e.target.value))
							}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Giá giảm"
						></TextField>
					</Grid>
					<Grid>
						<FormControl
							fullWidth
							error={
								!!formik.errors.brandId &&
								formik.touched.brandId
							}
						>
							<Autocomplete
								options={brand?.items || []}
								value={
									brand?.itemsMap.get(
										formik.values.brandId || ""
									) || {}
								}
								onChange={(e, value) => {
									formik.setValues({
										...formik.values,
										brandId: value?.id,
									});
								}}
								getOptionLabel={(option) => option?.name || ""}
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label="Hãng"
										variant="outlined"
									/>
								)}
							/>
							<FormHelperText>
								{formik.touched.brandId &&
									formik.errors.brandId}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid>
						<TextField
							value={formik.values.desc}
							helperText={
								formik.touched.desc && formik.errors.desc
							}
							variant="outlined"
							name="desc"
							error={!!formik.errors.desc && formik.touched.desc}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							fullWidth
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							label="Mô tả"
						></TextField>
					</Grid>
				</Grid>
			</BaseDialog>
		</Grid>
	);
}
