import {
	Grid,
	IconButton,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { IncomeHelper } from "../../helpers/IncomeHelper";
import { formatterMoney } from "../../helpers/StringHelper";
import { Income, IncomeSource } from "../../model/base-gift-card/model/Income";
import { useGlobalStyles } from "../../style/GlobalStyle";

type Props = {
	item: Income;
	onDelete?: (item: Income) => void;
	onSeeDetail?: (item: Income) => void;
};
const useStyle = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1),
		border: `1px solid ${theme.palette.grey[200]}`,
		padding: theme.spacing(2),
		borderRadius: theme.spacing(2),
	},
	iconDelete: {
		color: theme.palette.error.main,
		transition: "0.3s",
		"&:hover": {
			background: theme.palette.error.main,
			color: "white",
		},
	},
	iconDown: {
		fontSize: 30,
		color: theme.palette.error.main,
		fontWeight: 650,
		marginRight: theme.spacing(3),
	},
	iconUp: {
		fontSize: 30,
		color: theme.palette.success.main,
		fontWeight: 650,
		marginRight: theme.spacing(3),
	},
	money: {
		fontWeight: 550,
	},
	date: {
		fontSize: "0.8rem",
		color: theme.palette.grey[800],
	},
}));
function IncomeItem(props: Props) {
	const classes = useStyle();
	const globalStyles = useGlobalStyles();
	// const [state, setState] = useState();
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<Grid
			justify="space-between"
			alignItems="center"
			container
			className={classes.root}
		>
			<Grid>
				<Grid container>
					<Grid>
						{IncomeHelper.isIncomeUp(
							props.item.incomeSource as any
						) ? (
							<BsArrowUp className={classes.iconUp} />
						) : (
							<BsArrowDown className={classes.iconDown} />
						)}
					</Grid>
					<Grid>
						<Grid className={classes.money}>
							{" "}
							{formatterMoney.format(props.item.amount || 0)}
						</Grid>
						<Grid className={classes.date}>
							{moment(props.item.createdAt).format(
								"hh:mm DD/MM/YYYY"
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid>
				<Grid container alignItems="center">
					{props.onSeeDetail && (
						<Tooltip title="Delete">
							<IconButton
								// className={classes.}
								onClick={() => {
									props.onSeeDetail &&
										props.onSeeDetail(props.item);
								}}
							>
								<AiOutlineEye />
							</IconButton>
						</Tooltip>
					)}
					<Typography
						color="secondary"
						variant="body2"
						className={globalStyles.pr2}
					>
						{IncomeHelper.getLabelFromIncome(
							props.item.incomeSource as any
						)}
					</Typography>
					<Grid
						style={{
							paddingRight:
								(props.item.incomeSource ===
									IncomeSource.ADMIN ||
									props.item.incomeSource ===
										IncomeSource.DEDUCT_BY_ADMIN) &&
								props.onDelete
									? 0
									: 50,
						}}
					>
						{(props.item.incomeSource === IncomeSource.ADMIN ||
							props.item.incomeSource ===
								IncomeSource.DEDUCT_BY_ADMIN) &&
							props.onDelete && (
								<Tooltip title="Delete">
									<IconButton
										className={classes.iconDelete}
										onClick={() => {
											props.onDelete &&
												props.onDelete(props.item);
										}}
									>
										<AiFillDelete />
									</IconButton>
								</Tooltip>
							)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default React.memo(IncomeItem);
