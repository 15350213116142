import { BaseModel } from 'luong-base-model';
import { Brand } from './Brand';

export class GiftCard extends BaseModel {
  codeCard?: string;

  codePin?: string;

  denomination?: number;

  desc?: string;

  brandId?: string;

  usedAt?: Date;

  brand?: Brand;

  priceSale?: number;

  percentDiscount?: number;
}
