import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { IoLogoFacebook } from "react-icons/io";
import { SiMessenger } from "react-icons/si";
import { useGlobalStyles } from "../style/GlobalStyle";
import theme from "../theme/MuiTheme";
const useStyle = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(5),
	},
	text: {
		padding: theme.spacing(1),
	},
	frCopyRight: {
		borderTop: `1px solid ${theme.palette.grey[100]}`,
	},
	title: {
		fontWeight: 550,
		padding: theme.spacing(1),
	},
}));
export default function Footer() {
	const classes = useStyle();
	const globalStyle = useGlobalStyles();
	return (
		<Grid
			container
			justify="center"
			style={{
				background: theme.palette.primary.main,
				color: "white",
			}}
		>
			<Grid xs={8} container className={classes.root}>
				<Grid
					md={6}
					container
					justify="flex-start"
					className={globalStyle.pp1}
				>
					<Grid>
						<Grid container direction="row" wrap="nowrap">
							<Grid>
								<Typography className={classes.title}>
									Công ty
								</Typography>
								<Typography className={classes.title}>
									Địa chỉ
								</Typography>
								<Typography className={classes.title}>
									Số điện thoại
								</Typography>
							</Grid>
							<Grid>
								<Typography className={classes.text}>
									Gold Beehive LTD
								</Typography>
								<Typography className={classes.text}>
								02 Commercial Street, E1 6LP Lodon
								</Typography>
								<Typography className={classes.text}>
									0754.284.3503
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					md={6}
					container
					justify="flex-start"
					className={globalStyle.pp1}
				>
					<Grid>
						<Grid container direction="column">
							<Grid container>
								<Typography variant="h5">
									Càng vào sâu càng gây cấn
								</Typography>
							</Grid>
							<Grid container>
								<Grid
									container
									alignContent="center"
									alignItems="center"
								>
									<Grid>
										<IoLogoFacebook />
									</Grid>
									<Grid>
										<Typography className={classes.text}>
											{" "}
											www.facebook.com
										</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									alignContent="center"
									alignItems="center"
								>
									<Grid>
										<SiMessenger />
									</Grid>
									<Grid>
										<Typography className={classes.text}>
											{" "}
											www.messenger.com
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container justify="center" className={classes.frCopyRight}>
				<Typography
					style={{
						padding: theme.spacing(2),
					}}
					align="center"
				>
					Copy right Ale@luong
				</Typography>
			</Grid>
		</Grid>
	);
}
