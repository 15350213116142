/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Brand } from "../../model/base-gift-card/model/Brand";
import { Customer } from "../../model/base-gift-card/model/Customer";
import { useGlobalStyles } from "../../style/GlobalStyle";
import BaseDialog from "../genaral-component/BaseDialog";
const CKEditor = require("ckeditor4-react");

type Props = {
	isDisplay: boolean;
	item: Customer;
	onEdit: (item: Customer) => void;
	onCancel: () => void;
};
const validate = Yup.object({
	name: Yup.string()
		.max(100, "Chữ không được quá 100 kí tự")
		.required("Không được để trống !!")
		.trim().nullable(),
});

export default function PopupBrand(props: Props) {
	const formik = useFormik<Brand>({
		initialValues: {
			desc: "",
			name: "",
		} as Brand,
		validationSchema: validate,
		onSubmit: () => {
			props.onEdit(formik.values);
		},
	});
	const onSubmit = () => {
		formik.handleSubmit();
		formik.setTouched(_.mapValues(new Brand(), () => true));
	};

	useEffect(() => {
		if (props.isDisplay) {
			formik.setValues(
				_.cloneDeep({
					...props.item,
				})
			);
			formik.setTouched(_.mapValues(new Brand(), () => false));
		}
	}, [props]);
	const globalStyles = useGlobalStyles();
	return (
		<Grid>
			<BaseDialog
				isDisplay={props.isDisplay}
				onCancel={props.onCancel}
				onClickConfirm={() => {
					onSubmit();
				}}
				title="Hãng"
			>
				<Grid container direction="column" justify="space-around">
					<Grid>
						<TextField
							value={formik.values.name}
							helperText={
								formik.touched.name && formik.errors.name
							}
							name="name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							fullWidth
							variant="outlined"
							className={clsx(globalStyles.mt1, globalStyles.mb2)}
							label="Tên hãng"
						></TextField>
					</Grid>
					<Grid>
						<CKEditor
							className={clsx(globalStyles.mt2, globalStyles.mb2)}
							data={formik.values.desc}
							onChange={(e: any) => {
								formik.setValues({
									...formik.values,
									desc: e.editor.getData(),
								});
							}}
						/>
					</Grid>
				</Grid>
			</BaseDialog>
		</Grid>
	);
}
