import React, { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";

import { createBrowserHistory } from "history";
import AdminScreen from "../container/AdminScreen";
import CustomerScreen from "../container/CustomerScreen";
import { InfoMe } from "../model/base-gift-card/controller/IUserController";
export const history = createBrowserHistory({});

// Auth route componnet
export function AdminRoute({ ...props }: iProtectRoute) {
	return useMemo(
		() => (
			<Route
				path={props.path}
				render={({ location }) =>
					props.authen?.role == "admin" ? (
						<AdminScreen>
							<props.component />
						</AdminScreen>
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: location },
							}}
						/>
					)
				}
			/>
		),
		[props]
	);
}

// Guest route component
export function CustomerRoute({ ...props }: iProtectRoute) {
	return useMemo(
		() => (
			<Route
				exact={props.exact}
				path={props.path}
				render={({ location }) =>
					props.authen?.role == "customer" ? (
						<CustomerScreen>
							<props.component />
						</CustomerScreen>
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: location },
							}}
						/>
					)
				}
			/>
		),
		[props]
	);
}
export function AuthenRoute({ ...props }: iProtectRoute) {
	return useMemo(
		() => (
			<Route
				exact={props.exact}
				path={props.path}
				render={({ location }) =>
					Boolean(props.authen?.role) ? (
						<props.component />
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: location },
							}}
						/>
					)
				}
			/>
		),
		[props]
	);
}
export function PublicRoute({ ...props }: iProtectRoute) {
	return (
		<Route
			exact={props.exact}
			path={props.path}
			render={({ location }) => <props.component />}
		/>
	);
}

export const goTo = (path: string) => {
	history.push(path);
};

interface iProtectRoute extends iRoute {
	authen: InfoMe | undefined;
}

export interface iRoute {
	exact?: boolean;
	path: string;
	component: any;
	requireGuest?: boolean;
	requireAuth?: boolean;
}
