import { FormControl, FormHelperText } from "@material-ui/core";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
type Props = {
	value: string;
	onChange: (value: string) => void;
	textHelper?: string | undefined | boolean;
};

export default function PhoneNumberInput(props: Props) {
	return (
		<FormControl fullWidth>
			<PhoneInput
				country={"vn"}
				containerClass="input-phone"
				value={props.value}
				onChange={(phone) => {
					props.onChange(phone);
				}}
			/>
			<FormHelperText id="component-helper-text">
				{props.textHelper}
			</FormHelperText>
		</FormControl>
	);
}
